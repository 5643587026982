import React, { useState, useEffect } from "react";
import { API_URL } from "./config";
import AdminHeader from "./AdminHeader";

const ConfigureBulkApi = () => {
    // const [apiKey, setApiKey] = useState("");
    // const apiKey = [...Array(15)].map(() => Math.random().toString(36)[2].join(''));
    const [apiKey, setApikey] = useState('');
    const [apiStaus, setApiStaus] = useState('');
    const [apiValid, setApiValid] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isConfigured, setIsConfigured] = useState(false);
    let [isAdmin, setIsAdmin] = useState(false);

    const id = localStorage.getItem('async_client_profile_id');

    const [user_email, setUserEmail] = useState(localStorage.getItem('async_username'));
    const [username, setUsername] = useState(localStorage.getItem('userName'));

    const generateKey = () => {

        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        let apiKey = '';

        for (let i = 0; i < 15; i++) {
            const randomIndex = Math.floor(Math.random() * characters.length);
            apiKey += characters[randomIndex];
        }

       setApikey(apiKey)
       console.log('name',username);
       console.log('email',user_email);

    }

    const checkApiKey = () =>{
        fetch(`${API_URL}/clients/${id}`).then(res => {
            return res.json()
        }).then(resp => {
            setApiStaus(resp[0].apikey_status);
            if(resp[0].apikey_status === 'Active')
            {
                setIsConfigured(true);
            }
            
        }).catch((err) => {
            console.log(err.message);
        })
    }

    useEffect(() => {
        const role = localStorage.getItem('async_role');
        if (role === 'Admin') {
            setIsAdmin(true);
        }
        if (role === '' || role === null) {
            window.location.href = '/';
        }
        checkApiKey();
        generateKey();
    }, [])

    const configKey = () => {
        const status = 'Active';
        const userObj = { apiKey, status }

        setIsLoading(true);
        fetch(`${API_URL}/clients/configapi/${id}`, {
            method: "PUT",
            headers: { "content-type": "application/json" },
            body: JSON.stringify(userObj)
        }).then(res => {
            setIsLoading(false);
            setIsConfigured(true);
            sendApiEmail(apiKey);
        }).catch((err) => {
            console.log(err.message)
        })
    }

    const sendApiEmail = (apikey) => {
        const userObj = { username, user_email, apikey };
        console.log(userObj);

        fetch(`${API_URL}/mailer/apikey/`, {
            method: "POST",
            headers: { "content-type": "application/json" },
            body: JSON.stringify(userObj)
        }).then(res => {
            // alert('saved successfully!');
        }).catch((err) => {
            console.log(err.message)
        })
    }

    const handleFormSubmit = (e) => {
        e.preventDefault();
        console.log(id);
        configKey();
    };

    return (

        <div>
            {isAdmin ? <AdminHeader /> : null}
            {/* {!isAdmin ? <Header /> : null} */}
            <body class="sb-nav-fixed">
                <div id="layoutSidenav">
                    <div id="layoutSidenav_content">
                        <main>
                            <div class="container-fluid px-4">

                                <div className="container">
                                    <h2 style={{ marginTop: '20px' }}>Configure Bulk Message API</h2><br></br>
                                    {isConfigured ? (
                                        <div className="alert alert-success" role="alert">
                                            API configured successfully! Check your email
                                        </div>
                                    ) : (
                                        <form onSubmit={handleFormSubmit}>
                                            <div className="mb-3">
                                                <p className="form-label" style={{ textAlign: 'left' }}>
                                                    The Message API endpoint allows you to send SMS messages to mobile phone numbers. It provides a convenient way to quickly deliver important notifications, alerts, or promotional messages to your users. With this endpoint, you can integrate SMS functionality into your applications, enabling seamless communication with your customers.
                                                </p>
                                            </div>
                                            <div className="mb-3">

                                            </div>
                                            <button type="submit" className="btn btn-primary" disabled={isLoading}>
                                                {isLoading ? "Configuring..." : "Configure API"}
                                            </button>
                                        </form>
                                    )}
                                </div>
                            </div>
                        </main>
                    </div>
                </div>
            </body>
        </div>


    );
};

export default ConfigureBulkApi;