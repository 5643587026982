import React, { useState } from "react";
import { useEffect } from "react";
// import { useNavigation, useParams } from "react-router-dom";
import { Link, useNavigate } from "react-router-dom";
import Header from "./Header";
import AdminHeader from "./AdminHeader"
import { API_URL } from "./config";
import { toast } from "react-toastify";
import Swal from "sweetalert2";

const Contacts = () => {

    const [contactList, setContactList] = useState([]);
    const [isAdmin, setIsAdmin] = useState('');
    const navigate = useNavigate();

    useEffect(() => {
        const role = localStorage.getItem('async_role');
        if (role === 'Admin') {
            setIsAdmin(true);
        }
        if(role === '' || role === null){
            window.location.href = '/';
        }

        const client_profile_id = localStorage.getItem('async_client_profile_id');
        fetch(`${API_URL}/contacts/contacts/${client_profile_id}`).then(res => {
            return res.json()
        }).then(resp => {
            console.log(resp);
            setContactList(resp);
        }).catch((err) => {
            console.log(err.message);
        })
    }, [])

    const HandleRemove = (id) => {
        if (window.confirm('Do you want to remove this contact?')) {
            fetch(`${API_URL}/contacts/${id}`, {
                method: "DELETE"
            }).then(resp => {
                Swal.fire({                                            
                    text: "successfully deleted!",
                    icon: "success"
                  });
                navigate(0);
            }).catch((err) => {
                console.log(err.message);
            })
        }
    }

    return (
        <div>
            {isAdmin ? <AdminHeader /> : null}
            {/* {!isAdmin ? <Header /> : null} */}
            <body class="sb-nav-fixed">
                <div id="layoutSidenav">
                    <div id="layoutSidenav_content">
                        <main>
                            <div class="container-fluid px-4">
                                <h1 class="mt-4">Contacts</h1>
                                <ol class="breadcrumb mb-4">
                                    <li class="breadcrumb-item active"></li>
                                </ol>
                                <div>
                                    <Link className="btn btn-success btnAdd" style={{ float: "right" }} to="/AddContacts">Add Contact</Link>
                                </div>
                                <div>
                                    <Link className="btn btn-success btnAdd" style={{ float: "right" }} to="/groups">View Groups</Link>
                                </div>
                                <table className="table table-bordered">
                                    <thead className="bg-dark text-light">
                                        <tr>
                                            {/* <td style={{ color: 'white' }}>ID</td> */}
                                            <td style={{ color: 'white' }}>Name</td>
                                            <td style={{ color: 'white' }}>Surname</td>
                                            <td style={{ color: 'white' }}>Phone</td>
                                            <td style={{ color: 'white' }}>Email</td>
                                            {/* <td style={{ color: 'white' }}>Client Id</td> */}
                                            <td style={{ color: 'white' }}>Action</td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            contactList.map((item) => (
                                                <tr key={item.contact_id}>
                                                    <td>{item.contact_name}</td>
                                                    <td>{item.contact_surname}</td>
                                                    <td>{item.phone}</td>
                                                    <td>{item.email}</td>
                                                    {/* <td>{item.client_id}</td> */}
                                                    <td>
                                                        <Link to={"/EditContact/" + item.contact_id}>Edit </Link>__
                                                        <Link onClick={() => { HandleRemove(item.contact_id) }} style={{color: "blue", cursor:"pointer"}} >Remove</Link>
                                                    </td>
                                                </tr>
                                            ))
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </main>
                    </div>
                </div>
            </body>
        </div>
    );
}

export default Contacts;