import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { MD5 } from 'crypto-js';
import { API_URL } from './config';
import axios from "axios";
import sms6 from './sms6.png';
import { BarLoader } from "react-spinners";
import { toast } from "react-toastify";
import Swal from "sweetalert2";

const SelfRegistration = () => {

    const navigate = useNavigate();

    const [company_name, setCompany_name] = useState('');
    const [house_number_and_street_name, setHouse_number_and_street_name] = useState('');
    const [surbub, setSurbub] = useState('');
    const [city, setCity] = useState('');
    const [country, setCountry] = useState('');
    const [phoneno1, setPhoneno1] = useState('');
    const [phoneno2, setPhoneno2] = useState('');
    const [company_email, setCompany_email] = useState('');
    const [payment_style, setPayment_style] = useState('Prepaid'); //check
    const [username, setUsername] = useState('');
    const [user_email, SetUser_email] = useState('');
    const [password, setPassword] = useState('');
    const [nation_id_image, setNnation_id_image] = useState('');
    const [pdf_file, setPdf_file] = useState();
    const [status, setStatus] = useState("Pending");
    const [isAdmin, setIsAdmin] = useState('');
    const [img, setImg] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    const [natureOfCom, setNatureOfCom] = useState('');
    const [purpose, setPurpose] = useState('');

    //Email Application Form
    // const [address, setAddress] = useState('');

    //Get Ref
    const GetRef = async (user_email) => {
        try {
            fetch(`${API_URL}/selftregistration/ref/${user_email}`).then(res => {
                return res.json()
            }).then(resp => {
                console.log("get ref: ")
                console.log(resp[0].registration_id)
                sendGreetingEmail(resp[0].registration_id)
                Swal.fire({                                            
                    text: "Step 1 completed successfully! \n To complete the registration process, fill in the application form sent to you via email at stage 1. Then follow stage 2.",
                    icon: "success"
                  });
                navigate('/uploadappform')
            }).catch((err) => {
                console.log(err.message);
            })
        } catch (err) {
            console.log(err.message);
        }
    };


    // Create Email Object & Send Email
    const sendEmail = (ref) => {
        const address = house_number_and_street_name + ", " + surbub + ", " + city + " " + country;
        const userObj = { company_name, address, phoneno1, phoneno2, company_email, payment_style, username, user_email, ref };
        console.log(userObj);

        fetch(`${API_URL}/mailer/appform`, {
            method: "POST",
            headers: { "content-type": "application/json" },
            body: JSON.stringify(userObj)
        }).then(res => {
            // alert('email sent!');
        }).catch((err) => {
            console.log(err.message)
        })
    }

    // Create Email Object & Send Email
    const sendGreetingEmail = (ref) => {
        const userObj = { username, user_email, ref };
        console.log(userObj);

        fetch(`${API_URL}/mailer/`, {
            method: "POST",
            headers: { "content-type": "application/json" },
            body: JSON.stringify(userObj)
        }).then(res => {
            sendEmail(ref);
            // alert('saved successfully!');
        }).catch((err) => {
            console.log(err.message)
        })
    }

    const [selectedFile, setSelectedFile] = useState(null);
    const [selectedImage, setSelectedImage] = useState(null);

    const handleFileChange = (event) => {
        setSelectedFile(event.target.files[0]);
    };
    const handleImgChange = (event) => {
        setSelectedImage(event.target.files[0]);
    };

    const handleImageUpload = (event) => {
        event.preventDefault(); // Prevent default form submission
        setIsLoading(true);
        if (selectedImage) {
            console.log("Selected file true");
            const formData = new FormData();
            formData.append('pdfFile', selectedImage);

            axios.post(`${API_URL}/upload`, formData)
                .then((response) => {
                    console.log(response.data); // Response body containing the success message and filename

                    const lines = response.data.split('\n'); // Split the response by lines
                    if (lines.length > 0) {
                        const firstLine = lines[0]; // Get the first line
                        const imgname = firstLine.split('Filename: ')[1]; // Extract the filename from the first line
                        const imgpath = `${API_URL}/file/` + imgname;

                        if (imgname) {
                            console.log(imgname); // Log the filename
                            // or use it in your application as needed
                        } else {
                            console.log('Filename not found in the response');
                            setIsLoading(false);
                            Swal.fire({                                            
                                text: "Upload ID to continue",
                                icon: "error"
                              });
                        }

                        // Call the handleSubmit function passing the filename if needed
                        // handleFileUpload(imgpath);
                        handleSubmit(imgpath);
                    } else {
                        console.log('Invalid response format');
                    }
                })
                .catch((error) => {
                    console.error(error);
                    // Error occurred during file upload
                    console.log("Error response");
                    setIsLoading(false);
                    Swal.fire({                                            
                        text: "An error occured during file upload",
                        icon: "error"
                      });
                });

        } else {
            console.log('No file selected.');
            setIsLoading(false);
            Swal.fire({                                            
                text: "Upload ID to continue",
                icon: "error"
              });
        }
    };

    const handleFileUpload = (imgpath) => {
        if (selectedFile) {
            console.log("Selected file true");
            const formData = new FormData();
            formData.append('pdfFile', selectedFile);

            axios.post(`${API_URL}/upload`, formData)
                .then((response) => {
                    console.log(response.data); // Response body containing the success message and filename

                    const lines = response.data.split('\n'); // Split the response by lines
                    if (lines.length > 0) {
                        const firstLine = lines[0]; // Get the first line
                        const filename = firstLine.split('Filename: ')[1]; // Extract the filename from the first line
                        const filepath = `${API_URL}/file/` + filename;

                        if (filename) {
                            console.log(filename); // Log the filename
                            // or use it in your application as needed
                        } else {
                            console.log('Filename not found in the response');
                        }

                        // Call the handleSubmit function passing the filename if needed
                        handleSubmit(imgpath, filepath);
                    } else {
                        console.log('Invalid response format');
                    }
                })
                .catch((error) => {
                    console.error(error);
                    // Error occurred during file upload
                    console.log("Error response");
                });

        } else {
            console.log('No file selected.');
        }
    };

    const redirect = () => {
        window.location.href = '/uploadappform';
    }



    // const handleUpload = () => {
    //     // Perform the file upload logic here
    //     if (selectedFile) {
    //         // Use the selectedFile to upload to the server or perform any other operation
    //         console.log('Uploading file:', selectedFile);
    //     } else {
    //         console.log('No file selected.');
    //     }
    // };

    // const navigate = useNavigate();

    // const showImg = () => {
    //     const formData = new FormData();
    //     formData.append('name', img);
    //     axios.post('', formData).then((res) => {
    //         alert('uploaded successfully!')
    //     }).catch((err) => {
    //         alert('upload failed!');
    //     })
    //     console.log(img)
    // }

    const handleSubmit = (nation_id_image) => {
        // e.preventDefault();
        const passwordHash = MD5(password).toString();
        const pdf_file = '';
        const userObj = { company_name, house_number_and_street_name, surbub, city, country, phoneno1, phoneno2, company_email, payment_style, username, user_email, passwordHash, nation_id_image, pdf_file, status, natureOfCom, purpose };
        console.log(userObj);
        try {
            fetch(`${API_URL}/selftregistration`, {
                method: "POST",
                headers: { "content-type": "application/json" },
                body: JSON.stringify(userObj)
            }).then(res => {
                GetRef(user_email);
            }).catch((err) => {
                console.log(err.message)
            })
        } catch (error) {
            Swal.fire({                                            
                text: "Failed to submit details, check your interner connection",
                icon: "error"
              });
        }
    }

    return (
        <body className="sb-nav-fixed">

            <div id="layoutSidenav">

                <div id="layoutSidenav_content">
                    <main>
                        <div className="container-fluid px-4"
                            style={{
                                // height:'100%',
                                // width:'100%',
                                // opacity: '50%',
                                position: 'absolute',
                                resizeMode: 'stretch',
                                backgroundImage: `url('${sms6}')`,
                            }}
                        >
                            {/* <h1 className="mt-4">Create User</h1> */}
                            <ol className="breadcrumb mb-4">
                                <li className="breadcrumb-item active"></li>
                            </ol>
                            <form className="container" onSubmit={handleImageUpload}>
                                <div className="row">
                                    <div className="offset-lg-12">
                                        <div className="row">
                                            <div className="col-lg-2">

                                            </div>
                                            <div className="col-lg-8">
                                                <h6>Welcome to</h6>
                                                <h3 style={{ color: '#0842a0' }}>Tell Them Message Service </h3>
                                                <h6>Self Registration Portal </h6>
                                                <br></br>
                                                <div className="row mb-6" style={{ marginBottom: '-5px' }}>
                                                    <div className="col-lg-6 col-xs" style={{ paddingRight: '0%' }}>
                                                        <button type="button" className="btn btn-info form-control" style={{ float: "left", backgroundColor: "#2299f2" }}>Step 1: Create Application Form     </button>
                                                    </div>
                                                    <div className="col-lg-6" style={{ paddingLeft: '0%' }}>
                                                        <button type="button" className="btn btn-info form-control" style={{ float: "left", backgroundColor: "#1FBED6", color: "#000000"}} onClick={redirect}>Step 2: Upload Application Form</button>
                                                    </div>
                                                </div>
                                                <div className="card"
                                                    style={{ borderWidth: 1, borderColor: '#0096ff80' }}
                                                >
                                                    <div className="card-header"
                                                        style={{ backgroundColor: '#2299f2' }}
                                                    >
                                                        <h5>Enter Your Details</h5>
                                                    </div>
                                                    <div className="card-body">
                                                        <div className="row">
                                                            <div className="col-lg-1">

                                                            </div>
                                                            <div className="col-lg-10">
                                                                <div className="form-group row col-lg-12" >
                                                                    <div className=" col-lg-3 col-md-3" align="left" ><label>Company Name</label> <br></br></div>
                                                                    <div className=" col-lg-9 col-md-9" align="left" ><input style={{ borderWidth: 1, borderColor: '#00000070' }} type="text" value={company_name} onChange={e => setCompany_name(e.target.value)} className="form-control" required /><br></br></div>
                                                                </div>
                                                                <div className="form-group row col-lg-12">
                                                                <div className=" col-lg-3 col-md-3" align="left" ><label>Street</label> <br></br></div>
                                                                <div className=" col-lg-9 col-md-9" align="right" ><input style={{ borderWidth: 1, borderColor: '#00000070' }} type="text" value={house_number_and_street_name} onChange={e => setHouse_number_and_street_name(e.target.value)} className="form-control" required/><br></br></div>
                                                                </div>
                                                                <div className="form-group row col-lg-12">
                                                                    <div className=" col-lg-3 col-md-3" align="left" ><label>Surbub</label> <br></br></div>
                                                                    <div className=" col-lg-9 col-md-9" align="left"> <input style={{ borderWidth: 1, borderColor: '#00000070' }} type="text" value={surbub} onChange={e => setSurbub(e.target.value)} className="form-control" required/><br></br></div>
                                                                </div>
                                                                <div className="form-group row col-lg-12">
                                                                <div className=" col-lg-4 col-md-4" align="left" ><label>City</label> <br></br></div>
                                                                <div className=" col-lg-8 col-md-8" align="left" > <input style={{ borderWidth: 1, borderColor: '#00000070' }} type="text" value={city} onChange={e => setCity(e.target.value)} className="form-control" required/><br></br></div>
                                                                </div>
                                                                <div className="form-group row col-lg-12">
                                                                <div className=" col-lg-3 col-md-3" align="left" ><label>Country</label> <br></br></div>
                                                                <div className=" col-lg-9 col-md-9" align="left" >   <input style={{ borderWidth: 1, borderColor: '#00000070' }} type="text" value={country} onChange={e => setCountry(e.target.value)} className="form-control" required/><br></br></div>
                                                                </div>
                                                                <div className="form-group row col-lg-12">
                                                                <div className=" col-lg-6 col-md-6" align="left" ><label>Phone no1 eg. +263777000000</label> <br></br></div>
                                                                <div className=" col-lg-6 col-md-6" align="left" >   <input style={{ borderWidth: 1, borderColor: '#00000070' }} type="text" value={phoneno1} onChange={e => setPhoneno1(e.target.value)} className="form-control" required/><br></br></div>
                                                                </div>
                                                                <div className="form-group row col-lg-12">
                                                                <div className=" col-lg-6 col-md-6" align="left" ><label>Phone no2  eg. +263777000000</label> <br></br></div>
                                                                <div className=" col-lg-6 col-md-6" align="left" ><input style={{ borderWidth: 1, borderColor: '#00000070' }} type="text" value={phoneno2} onChange={e => setPhoneno2(e.target.value)} className="form-control"/><br></br></div>
                                                                </div>
                                                                <div className="form-group row col-lg-12">
                                                                <div className=" col-lg-3 col-md-3" align="left" ><label>Company Email</label> <br></br></div>
                                                                <div className=" col-lg-9 col-md-9" align="left" ><input style={{ borderWidth: 1, borderColor: '#00000070' }} type="email" value={company_email} onChange={e => setCompany_email(e.target.value)} className="form-control" required/><br></br></div>
                                                                </div>
                                                                <div className="form-group row col-lg-12">
                                                                <div className=" col-lg-3 col-md-3" align="left" ><label>Payment Style</label> <br></br></div>
                                                                <div className=" col-lg-9 col-md-9" align="left" ><input style={{ borderWidth: 1, borderColor: '#00000070' }} type="text" value={payment_style} onChange={e => setPayment_style(e.target.value)} className="form-control" required disabled/><br></br></div>
                                                                </div>
                                                                <div className="form-group row col-lg-12">
                                                                <div className=" col-lg-6 col-md-6" align="left" ><label>Nature of Company</label> <br></br></div>
                                                                <div className=" col-lg-6 col-md-6" align="left" ><input style={{ borderWidth: 1, borderColor: '#00000070' }} type="text" value={natureOfCom} onChange={e => setNatureOfCom(e.target.value)} className="form-control" required/><br></br></div>
                                                                </div>
                                                                <div className="form-group row col-lg-12">
                                                                <div className=" col-lg-6 col-md-6" align="left" ><label>Purpose of use of the service</label> <br></br></div>
                                                                <div className=" col-lg-6 col-md-6" align="left" ><input style={{ borderWidth: 1, borderColor: '#00000070' }} type="text" value={purpose} onChange={e => setPurpose(e.target.value)} className="form-control" required/><br></br></div>
                                                                </div>
                                                                <h3>Enter user details</h3>
                                                                <div className="form-group row col-lg-12">
                                                                <div className=" col-lg-4 col-md-4" align="left" ><label>Username</label> <br></br></div>
                                                                <div className=" col-lg-8 col-md-8" align="left" ><input style={{ borderWidth: 1, borderColor: '#00000070' }} type="text" value={username} onChange={e => setUsername(e.target.value)} className="form-control" required/><br></br></div>
                                                                </div>
                                                                <div className="form-group row col-lg-12">
                                                                <div className=" col-lg-4 col-md-4" align="left" ><label>Email</label> <br></br></div>
                                                                <div className=" col-lg-8 col-md-8" align="left" ><input style={{ borderWidth: 1, borderColor: '#00000070' }} type="email" value={user_email} onChange={e => SetUser_email(e.target.value)} className="form-control" required/><br></br></div>
                                                                </div>
                                                                {/* <div className="form-group">
                                                                    <label style={{ float: "left" }}>Password</label> <br></br>
                                                                    <input style={{ borderWidth: 1, borderColor: '#00000070' }} type="password" value={password} onChange={e => setPassword(e.target.value)} className="form-control" /><br></br>
                                                                </div> */}
                                                                <div>
                                                                    <label style={{ float: "left", color: "#57aad0" }}>Upload Representative National Identity Document (Picture)</label> <br></br><br></br>
                                                                    <input type="file" onChange={handleImgChange} style={{ float: "left" }} required/><br></br>
                                                                    {/* <button onClick={handleUpload}>Upload</button> */}
                                                                </div>
                                                                <div><br></br>
                                                                    <a href="/terms">Terms & Conditions</a>
                                                                </div>
                                                                {/* <div><br></br>
                                                                    <label style={{ float: "left" }}>Upload Pdf File</label> <br></br>
                                                                    <input type="file" onChange={handleFileChange} style={{ float: "left" }} /><br></br>
                                                                </div> */}
                                                            </div>
                                                            <div className="col-lg-1">

                                                            </div>
                                                        </div>

                                                    </div>
                                                    {isLoading && <BarLoader size={40} width={'100%'} color="blue" loading />}
                                                    <div className="card-footer">
                                                        <button type="submit" className="btn btn-success" style={{ float: "left" }}>Save</button>
                                                        {/* <button type="button" className="btn btn-success" style={{ float: "left" }}>Download</button> */}
                                                        {/* <button type="button" onClick={()=>showImg()} className="btn btn-success" style={{ float: "left" }}>Show Image</button> */}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-2">

                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </form>
                        </div>
                    </main>
                </div>
            </div>
        </body>
    );
}

export default SelfRegistration;