import React, { useState, useEffect } from "react";
import Header from "./Header";
import AdminHeader from "./AdminHeader"
import { API_URL } from "./config";
import axios from "axios";
import { toast } from "react-toastify";
import Swal from "sweetalert2";

const SendMessage = () => {

    const currectDate = new Date();
    const [currentPhone, setCurrentPhone] = useState("");
    //const [contactsgrp, setContactsgrp] = useState([]);
    const [smsCharge, setSmsCharge] = useState('0.046');

    //Update Topup Table
    const [currency, setCurrency] = useState("USD");
    const [exchange_rate, setExchange_rate] = useState('14');
    const [credit, setCredit] = useState(smsCharge);
    const [debit, setDebit] = useState('');
    const [balance, setBalance] = useState('');
    const [description, setDescription] = useState("sms sent");

    //Update SentMessages Table
    const [message_type, setmessage_type] = useState("sms");
    const [origin_phone, setorigin_phone] = useState('');
    const [dest_phone, setdest_phone] = useState([currentPhone]);
    const [date_sent, setdate_sent] = useState(currectDate);
    const [group_id, setgroup_id] = useState('1');
    const [contact_grouping_id, setContact_grouping_id] = useState('1');
    const [msgbody, setMsgBody] = useState('');
    const [client_profile_id, setCliient_profile_id] = useState(localStorage.getItem('async_client_profile_id'));
    const [vat, setVat] = useState('0.15');
    const [costIncl, setCostIncl] = useState('0.046');

    const [contacts, setContacts] = useState([]);
    const [groups, setGroups] = useState([]);
    const [contactGrps, setContactGrps] = useState([]);
    const [contactIDs, setContactIDs] = useState([]);

    const [options, setOptions] = useState([]);

    const [selectedCategory, setSelectedCategory] = useState('softworks');

    const [isAdmin, setIsAdmin] = useState('');

    useEffect(() => {
        const role = localStorage.getItem('async_role');
        if (role === 'Admin') {
            setIsAdmin(true);
        }
        if (role === '' || role === null) {
            window.location.href = '/';
        }

        fetch(`${API_URL}/groups/clients/${client_profile_id}`).then(res => {
            return res.json()
        }).then(resp => {
            setContactGrps(resp);
            console.log("rrr", resp)
        }).catch((err) => {
            console.log(err.message);
        })
    }, [])

    useEffect(() => {
        fetch(`${API_URL}/senderid/${client_profile_id}`).then(res => {
            return res.json()
        }).then(resp => {
            setOptions(resp);
            console.log("rrr", resp)
        }).catch((err) => {
            console.log(err.message);
        })
    }, [])

    //Add Group Selected Contacts
    const fillGroupContacts = (grpid) => {
        console.log(grpid);
        const existingIds = dest_phone && typeof dest_phone === "string" ? dest_phone.split(", ") : [];

        const newIds = contactGrps.reduce((acc, item) => {
            if (item.groupid === grpid) {
                const index = existingIds.indexOf(item.phone);
                if (index > -1) {
                    existingIds.splice(index, 1); // remove matching contact
                } else {
                    acc.push(item.phone); // add non-matching contact
                }
            }
            return acc;
        }, []);

        const newString = [...existingIds, ...newIds].join(", ");
        setdest_phone(newString);
    };

    //Checkbox change
    const handleCheckboxChange = (grpid) => {
        fillGroupContacts(grpid);
    };

    const sendMessage = () => {
        const userObj = { dest_phone, msgbody, selectedCategory };
        console.log(userObj);
        fetch(`${API_URL}/sendSMS`, {
            method: "POST",
            headers: { "content-type": "application/json" },
            body: JSON.stringify(userObj)
        }).then(res => {
            // alert('Message sent');
        }).catch((err) => {
            console.log(err.message)
        })
    };

    //Validation
    const validate = () => {
        if ((msgbody === '' || msgbody === null || msgbody === "") || (dest_phone === null || dest_phone.length === 0)) {
            Swal.fire({
                text: "Contact number and message cannot be empty",
                icon: "error"
            });
        } else {
            if (selectedCategory === '') {
                Swal.fire({
                    text: "Select a header",
                    icon: "warning"
                });
            } else {
                updateMessagesTable();
            }
        }
    }

    // Update Sent Messages Tbl  
    const updateMessagesTable = () => {
        let arr = []
        if (dest_phone.indexOf(',') > -1) {
            let spltd = dest_phone.split(",")
            for (var i = 0; i < spltd.length; i++) {
                console.log(spltd[i])
                arr.push(spltd[i])
            }
        } else {
            arr.push(dest_phone)
        }

        const sentMessagesObj = { client_profile_id, message_type, origin_phone, arr, date_sent, group_id, contact_grouping_id, msgbody, currency, exchange_rate, credit, debit, balance, description, vat, costIncl };
        console.log(sentMessagesObj);

        const bal = localStorage.getItem('cliBal');
        const count = (dest_phone.split(",").length);
        const charge = smsCharge * count;

        console.log(count); //
        console.log(charge); //
        console.log(bal);
        if (bal != null) {
            if (bal >= charge && bal) {
                fetch(`${API_URL}/sentmessages`, {
                    method: "POST",
                    headers: { "content-type": "application/json" },
                    body: JSON.stringify(sentMessagesObj)
                }).then(res => {
                    Swal.fire({
                        text: "Message Sent",
                        icon: "success"
                    });
                    sendMessage();
                }).catch((err) => {
                    console.log(err.message)
                })
            } else {
                Swal.fire({
                    text: "Please topup your balance to continue",
                    icon: "error"
                });
            }
        } else {
            Swal.fire({
                text: "Please topup your balance to continue",
                icon: "error"
            });
        }
    }

    const Clear = () => {
        setMsgBody('');
        setdest_phone('');
    }

    useEffect(() => {
        getBalance();
    })

    useEffect(() => {
        const client_profile_id = localStorage.getItem('async_client_profile_id');
        fetch(`${API_URL}/contacts/contacts/${client_profile_id}`).then(res => {
            return res.json()
        }).then(resp => {
            setContacts(resp);
            console.log(resp);
        }).catch((err) => {
            console.log(err.message);
        })
    }, [])

    useEffect(() => {
        const client_profile_id = localStorage.getItem('async_client_profile_id');
        fetch(`${API_URL}/groups/groups/${client_profile_id}`).then(res => {
            return res.json()
        }).then(resp => {
            setGroups(resp);
            console.log(resp);
        }).catch((err) => {
            console.log(err.message);
        })
    }, [])

    const getBalance = () => {

        fetch(`${API_URL}/topUp/lasttopup/${client_profile_id}`).then(res => {
            return res.json()
        }).then(resp => {
            console.log(resp.results[0].balance);
            localStorage.setItem('cliBal', resp.results[0].balance);
        }).catch((err) => {
            console.log(err.message);
        })
    }

    const handleContactCheckboxChange = (chkd, contactId) => {
        // alert(chkd)
        const checkedContact = contacts.find((contact) => contactId === contact.contact_id);

        if (checkedContact) {
            const existingIds = dest_phone && typeof dest_phone === "string" ? dest_phone.split(", ") : [];

            const newIds = contacts.reduce((acc, item) => {
                if (item.contact_id === contactId) {
                    const index = existingIds.indexOf(item.phone);
                    if (index > -1) {
                        existingIds.splice(index, 1); // remove matching contact
                    } else {
                        acc.push(item.phone); // add non-matching contact
                    }
                }
                return acc;
            }, []);

            const newString = [...existingIds, ...newIds].join(", ");
            setdest_phone(newString);

        } else {
            console.log('false')
            const existingIds = dest_phone && typeof dest_phone === "string" ? dest_phone.split(", ") : [];
            const updatedIds = existingIds.filter((id) => id !== contactId);
            setdest_phone(updatedIds.join(", "));
        }
    };

    // const options = ['Info', 'Promotions', 'softworks'];


    return (
        <html>
            {isAdmin ? <AdminHeader /> : null}
            {/* {isAdmin ? <AdminHeader /> : null}
            {!isAdmin ? <Header /> : null} */}
            <body class="sb-nav-fixed">
                <div id="layoutSidenav">
                    <div id="layoutSidenav_content">
                        <main>
                            <div class="container-fluid px-4">
                                {/* <h1 class="mt-4">Create User</h1> */}
                                <ol class="breadcrumb mb-4">
                                    <li class="breadcrumb-item active"></li>
                                </ol>
                                <div className="container">
                                    <div className="row">
                                        <div className="col-lg-6">
                                            <div className="message-box">
                                                <h2>Send Message</h2>
                                                <form>
                                                    <label htmlFor="to" style={{ float: "left" }}>
                                                        Recipient: eg. +263777000000
                                                    </label>
                                                    <input
                                                        type="text"
                                                        value={dest_phone}
                                                        onChange={(e) => setdest_phone(e.target.value)}
                                                        id="to"
                                                        placeholder="receiver no."
                                                        disabled
                                                    // disabled="true"
                                                    />
                                                    <label htmlFor="message" style={{ float: "left" }}>
                                                        Message:
                                                    </label>
                                                    <textarea
                                                        id="message"
                                                        value={msgbody}
                                                        onChange={(e) => setMsgBody(e.target.value)}
                                                        placeholder="Enter message"
                                                    ></textarea>
                                                    <div>
                                                        <div style={{ float: 'right', paddingRight: '1rem' }}>
                                                            <div class='row'>
                                                                <div class='col-xl-5'>
                                                                <label htmlFor="header">Header</label>
                                                                </div>
                                                                <div class='col-xl-6'>
                                                                <select
                                                                    value={selectedCategory}
                                                                    onChange={e => setSelectedCategory(e.target.value)}
                                                                    id="to"
                                                                    placeholder="select category"
                                                                >
                                                                    {/* <option value="">Header</option> */}
                                                                    {options.map((option) => (
                                                                        <option key={option.sender_id} value={option.sender_name}>
                                                                            {option.sender_name}
                                                                        </option>
                                                                    ))}
                                                                </select>

                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div><br></br><br></br>
                                                    <button onClick={() => validate()} type="button"
                                                        style={{
                                                            backgroundColor: '#4CAF50', /* Green */
                                                            border: 'none',
                                                            color: 'white',
                                                            padding: '15px 32px',
                                                            textAlign: 'center',
                                                            fontSize: '16px'
                                                        }}
                                                    >
                                                        Send
                                                    </button>
                                                    <button onClick={() => Clear()} type="button"
                                                        style={{
                                                            marginLeft: '5px',
                                                            backgroundColor: 'tomato', /* Green */
                                                            border: 'none',
                                                            color: 'white',
                                                            padding: '15px 32px',
                                                            textAlign: 'center',
                                                            fontSize: '16px'
                                                        }}
                                                    >
                                                        Clear
                                                    </button>
                                                </form>
                                            </div>
                                        </div>
                                        <div className="col-lg-4">
                                            <div className="contact-box">
                                                <h3>Select Contacts</h3>
                                                <div style={{ marginLeft: '5px', textAlign: 'justify' }}>
                                                    {contacts.map((contact) => (
                                                        <div key={contact.contact_id} style={{ alignItems: "left" }}>
                                                            <input
                                                                type="checkbox"
                                                                id={contact.contact_id}
                                                                name={contact.contact_name}
                                                                onChange={(e) => handleContactCheckboxChange(e.target.checked, contact.contact_id)}
                                                            />
                                                            <label htmlFor={contact.contact_id}>{contact.contact_name} {contact.contact_surname} </label>
                                                            <br />
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                            <div className="contact-box">
                                                <h3>Select Groups</h3>
                                                <div style={{ marginLeft: '5px', textAlign: 'justify' }}>
                                                    {groups.map((group) => (
                                                        <div key={group.groupid}>
                                                            <label>
                                                                <input
                                                                    type="checkbox"
                                                                    id={group.groupid}
                                                                    name={group.grp_name}
                                                                    onChange={() => handleCheckboxChange(group.groupid)}
                                                                />
                                                                <strong>{group.grp_name}</strong>
                                                            </label>
                                                            <br />
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </main>
                    </div>
                </div>
            </body>
        </html>
    );
}

export default SendMessage;