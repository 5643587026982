import React, { useState } from "react";
import { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
//import { useNavigation, useParams } from "react-router-dom";
import Header from "./Header";
import AdminHeader from "./AdminHeader";
import { API_URL } from './config';
import { toast } from "react-toastify";
import Swal from "sweetalert2";

const Clients = () => {

    const [isAdmin, setIsAdmin] = useState('');
    const [userList, setUserList] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        const role = localStorage.getItem('async_role');
        if (role === 'Admin') {
            setIsAdmin(true);
        }
        if (role === '' || role === null) {
            window.location.href = '/';
        }
        fetch(`${API_URL}/clients`).then(res => {
            return res.json()
        }).then(resp => {
            setUserList(resp);
        }).catch((err) => {
            console.log(err.message);
        })
    }, [])

    const HandleRemove = (id) => {
        if (window.confirm('Do you want to remove this client?')) {
            fetch(`${API_URL}/clients/${id}`, {
                method: "DELETE"
            }).then(resp => {
                Swal.fire({                                            
                    text: "saved successfully!",
                    icon: "success"
                  });
                navigate(0);
            }).catch((err) => {
                console.log(err.message);
            })
        }
    }

    return (
        <div>
            {isAdmin ? <AdminHeader /> : null}
            {/* {!isAdmin ? <Header /> : null} */}
            <body class="sb-nav-fixed">

                <div id="layoutSidenav">

                    <div id="layoutSidenav_content">
                        <main>
                            <div class="container-fluid px-4">
                                <h1 class="mt-4">Clients</h1>
                                <ol class="breadcrumb mb-4">
                                    <li class="breadcrumb-item active"></li>
                                </ol>
                                <div style={{}}>
                                    <Link className="btn btn-success btnAdd" style={{ float: "right" }} to="/AddClient">Register Client</Link>
                                </div>
                                <table className="table table-bordered">
                                    <thead className="bg-dark text-light">
                                        <tr>
                                            <td style={{ color: 'white' }}>ID</td>
                                            <td style={{ color: 'white' }}>Name</td>
                                            <td style={{ color: 'white' }}>Account Type</td>
                                            <td style={{ color: 'white' }}>Email</td>
                                            <td style={{ color: 'white' }}>Phone</td>
                                            <td style={{ color: 'white' }}>City</td>
                                            <td style={{ color: 'white' }}>Payment type</td>
                                            <td style={{ color: 'white' }}>Status</td>
                                            <td style={{ color: 'white' }}>Action</td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            userList.map((item) => (
                                                <tr key={item.client_profile_id}>
                                                    <td>{item.client_profile_id}</td>
                                                    <td>{item.name}</td>
                                                    <td>{item.account_type}</td>
                                                    <td>{item.email}</td>
                                                    <td>{item.phoneno1}</td>
                                                    <td>{item.city}</td>
                                                    <td>{item.payment_style}</td>
                                                    <td>{item.membershipstatus}</td>
                                                    <td>
                                                        <Link to={"/EditClient/" + item.client_profile_id}>Edit </Link>_
                                                        <a onClick={() => { HandleRemove(item.client_profile_id) }} style={{ color: "blue" }} >Remove</a>
                                                    </td>
                                                </tr>
                                            ))
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </main>
                    </div>
                </div>
            </body>
        </div>
    );
}

export default Clients;