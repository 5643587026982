import React, { useState, useEffect } from "react";
import { MD5 } from 'crypto-js';
import { API_URL } from "./config";
import { toast } from "react-toastify";
import Swal from "sweetalert2";

const Otp = () => {

    const [email, setUsername] = useState(localStorage.getItem('async_username'));
    const [otp, setOtp] = useState('');
    const [isloading, setIsLoading] = useState(false);
    const [genOtp, setGenOtp] = useState(0);

    useEffect(() => {
        localStorage.removeItem('user');
        localStorage.removeItem('async_client_profile_id');
        localStorage.removeItem('async_role');
    }, []);



    const requestOtp = ()=>{

    }

    const generateOtp = () => {
        const min = 123456;
        const max = 987987;
        const random = min + (Math.floor(Math.random() * (max - min + 1)));
        console.log('random number: ', random);
        setOtp(random);
    }

    const handleSubmit = (email, otp) => {
        try {
            fetch(`${API_URL}/users/user/${email}/${otp}`)
                .then(res => res.json())
                .then(resp => {
                    console.log('function called 2');
                    console.log(resp.length);
                    if (resp.length > 0) {
                        console.log(resp[0].userid);
                        localStorage.setItem('user', resp[0].userid);
                        localStorage.setItem('async_client_profile_id', resp[0].client_profile_id);
                        localStorage.setItem('async_role', resp[0].role);
                        localStorage.setItem('async_username', resp[0].email);
                        const id = resp[0].userid;
                        window.location.href = '/passwordreset';
                    } else {
                        Swal.fire({                                            
                            text: "Login failed, incorrect username or password!",
                            icon: "error"
                          });
                        setIsLoading(false);
                    }
                })
                .catch(err => {
                    console.log(err.message);
                    setIsLoading(false);
                    Swal.fire({                                            
                        text: "Login failed!",
                        icon: "error"
                      });

                    // alert("Login failed, check your network connection!");
                });
        } catch (error) {
            console.log(error);

            Swal.fire({                                            
                text: "Failed to reset password!",
                icon: "error"
              });
        }
    };
    return (

        <div class="container-fluid" style={{ padding: 0, background: '#0d6efd' }}>

            <div class="row">

                <div class="col-lg-5">
                    <img src="/img/Bulk Text Smses Flyer.jpg" alt="Tell Them SMS GW" width="100%" height="100%" />
                </div>
                <div class="col-lg-2">

                </div>
                <div class="col-lg-4" style={{ width: '30%' }}>

                    <div class="card shadow-lg border-1 rounded-lg mt-5">
                        <div class="card-header"><h5 class="text-center font-weight-light my-4">Enter your OTP to proceed</h5></div>
                        <div class="card-body">
                            <form>                                
                                <div class="form-floating mb-3">
                                    <input type="text" value={otp} onChange={e => setOtp(e.target.value)} id="email" class="form-control" required placeholder="name@example.com" />
                                    <label for="inputEmail">Enter your email</label>
                                </div>
                                <div class="form-floating mb-3">
                                    <input type="text" value={otp} onChange={e => setOtp(e.target.value)} id="email" class="form-control" required placeholder="name@example.com" />
                                    <label for="inputEmail">Enter OTP</label>
                                </div>
                                <div class="d-flex align-items-center justify-content-between mt-4 mb-0">
                                    <button onClick={() => handleSubmit(email, otp)} class="btn btn-primary">Rquest OTP</button>
                                </div>
                                <div class="d-flex align-items-center justify-content-between mt-4 mb-0">
                                    <button onClick={() => handleSubmit(email, otp)} class="btn btn-primary">Enter</button>
                                </div>
                            </form>
                        </div>
                        <div class="card-footer text-center py-3">
                            <div class="small"><a href="Register">Register!</a></div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-1">

                </div>
            </div>
            <script src="https://cdn.jsdelivr.net/npm/bootstrap@5.2.3/dist/js/bootstrap.bundle.min.js" crossorigin="anonymous"></script>
            <script src="js/scripts.js"></script>
        </div>

    );
}

export default Otp;