import React, { useState } from "react";
import { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import Header from "./Header";
import AdminHeader from "./AdminHeader"
import { API_URL } from "./config";
import { toast } from "react-toastify";
import Swal from "sweetalert2";

const MyGroups = () => {

    const [userList, setUserList] = useState([]);
    const [isAdmin, setIsAdmin] = useState('');
    const [client_id] = useState(localStorage.getItem('async_client_profile_id'));
    const navigate = useNavigate();

    useEffect(() => {
        const role = localStorage.getItem('async_role');
        if (role === 'Admin') {
            setIsAdmin(true);
        }
        if (role === '' || role === null) {
            window.location.href = '/';
        }
        fetch(`${API_URL}/groups/groups/${client_id}`).then(res => {
            return res.json()
        }).then(resp => {
            console.log(resp)
            setUserList(resp);
        }).catch((err) => {
            console.log(err.message);
        })
    }, [client_id])

    const HandleRemove = (id) => {
        if (window.confirm('Do you want to remove this group?')) {
            fetch(`${API_URL}/groups/${id}`, {
                method: "DELETE"
            }).then(resp => {
                Swal.fire({                                            
                    text: "successfully deleted!",
                    icon: "success"
                  });
                navigate(0);
            }).catch((err) => {
                console.log(err.message);
            })
        }
    }


    return (
        <div>
             {isAdmin ? <AdminHeader/> : null}
            {/* {isAdmin ? <AdminHeader /> : null}
            {!isAdmin ? <Header /> : null} */}
            <body class="sb-nav-fixed">

                <div id="layoutSidenav">

                    <div id="layoutSidenav_content">
                        <main>
                            <div class="container-fluid px-4">
                                <h1 class="mt-4">GROUPS</h1>
                                <ol class="breadcrumb mb-4">
                                    <li class="breadcrumb-item active"></li>
                                </ol>
                                <div style={{}}>
                                    <Link className="btn btn-success btnAdd" style={{ float: "right" }} to="/AddGroup">Add Group</Link>
                                </div>
                                <table className="table table-bordered">
                                    <thead className="bg-dark text-light">
                                        <tr>
                                            <td style={{ color: 'white' }}>ID</td>
                                            <td style={{ color: 'white' }}>Name</td>
                                            <td style={{ color: 'white' }}>Action</td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            userList.map((item) => (
                                                <tr key={item.groupid}>
                                                    <td>{item.groupid}</td>
                                                    <td>{item.grp_name}</td>
                                                    <td>
                                                        {/* <Link to={"/editgroup/" + item.groupid}>Edit </Link>__ */}
                                                        <Link onClick={() => { HandleRemove(item.groupid) }} style={{ color: "blue" }} >Remove</Link>
                                                    </td>
                                                </tr>
                                            ))
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </main>
                    </div>
                </div>
            </body>
        </div>
    );
}

export default MyGroups;