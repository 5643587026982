import React, { useState, useEffect } from "react";
import { API_URL } from './config';
import axios from "axios";
import sms6 from './sms6.png';

const Terms = () => {

    return (
        <body className="sb-nav-fixed">

            <div id="layoutSidenav">

                <div id="layoutSidenav_content">
                    <main>
                        <div className="container-fluid px-4"
                            style={{
                                // height:'100%',
                                // width:'100%',
                                // opacity: '50%',
                                position: 'absolute',
                                resizeMode: 'stretch',
                                backgroundImage: `url('${sms6}')`,
                            }}
                        >
                            {/* <h1 className="mt-4">Create User</h1> */}
                            <ol className="breadcrumb mb-4">
                                <li className="breadcrumb-item active"></li>
                            </ol>
                            <form className="container" >
                                <div className="row">
                                    <div className="offset-lg-12">
                                        <div className="row">
                                            <div className="col-lg-3">

                                            </div>
                                            <div className="col-lg-6">
                                                <h6>Welcome to</h6>
                                                <h2>Tell Them Message</h2>
                                                <h5>Gateway</h5><br></br>
                                                <div className="card"
                                                    style={{ borderWidth: 1, borderColor: '#0096ff80' }}
                                                >
                                                    <div className="card-header"
                                                        style={{ backgroundColor: '#2299f2' }}
                                                    >
                                                        <h5>SMS Gateway Terms and Conditions</h5>
                                                    </div>
                                                    <div className="card-body">
                                                        <div className="row">
                                                            <div className="col-lg-1">

                                                            </div>
                                                            <div className="col-lg-10" style={{ textAlign: 'justify', fontSize:'10px'}}>
                                                                <h2 style={{textAlign: 'center'}}>Terms & Conditions</h2>
                                                                <p>
                                                                    <center>Terms and Conditions of Tell Them Message Service Gateway</center><br></br>
                                                                    <br></br><br></br><strong>1. Introduction</strong> <br></br>

                                                                    These Terms and Conditions ("Terms") govern the use of the Message Gateway ("Service") provided by Bonvyx Company PVT Ltd. By using the Service, you ("User") agree to be bound by these Terms.

                                                                    <br></br><br></br><strong>2. Definitions</strong> <br></br>
                                                                    "Message" means any SMS, MMS, Email or social media : (Thread, Post Comment) sent through the Service.
                                                                    "Recipient" means the intended recipient of a Message.

                                                                    <br></br><br></br><strong>3. Use of Service</strong> <br></br>

                                                                    The Service is intended for business use only.
                                                                    User shall not use the Service for illegal purposes, spamming, phishing, or sending unsolicited Messages.
                                                                    User shall comply with all applicable laws and regulations when using the Service.

                                                                    <br></br><br></br><strong>4. Message Content</strong> <br></br>

                                                                    User is responsible for the content of all Messages sent through the Service.
                                                                    User shall not send Messages that are illegal, fraudulent, or infringe on the rights of others.

                                                                    <br></br><br></br><strong>5. Payment Terms</strong> <br></br>

                                                                    User shall pay all fees associated with the Service, as specified in the pricing plan.
                                                                    Payment shall be made in advance, and the Service may be suspended if payment is not received.

                                                                    <br></br><br></br><strong>6. Data Privacy</strong> <br></br>

                                                                    Bonvyx Company PVT Ltd shall keep all Message data confidential and secure.
                                                                    Bonvyx Company PVT Ltd shall comply with all applicable data protection regulations.

                                                                    <br></br><br></br><strong>7. Warranty Disclaimer</strong> <br></br>

                                                                    The Service is provided on an "as is" and "as available" basis, without warranty of any kind.

                                                                    <br></br><br></br><strong>8. Limitation of Liability</strong> <br></br>

                                                                    Bonvyx Company PVT Ltd shall not be liable for any damages arising from the use of the Service.

                                                                    <br></br><br></br><strong>9. Termination</strong> <br></br>

                                                                    Either party may terminate this agreement with written notice.
                                                                    Upon termination, User shall pay all outstanding fees and cease using the Service.

                                                                    <br></br><br></br><strong>10. Governing Law</strong> <br></br>

                                                                    These Terms shall be governed by and construed in accordance with the laws of Zimbabwe
                                                                    By using the Service, User acknowledges that they have read, understood, and agree to be bound by these Terms.
                                                                </p>
                                                                <div className="col-lg-1">

                                                                </div>
                                                            </div>
                                                        </div>

                                                    </div>
                                                    <div className="card-footer">
                                                        <h6 style={{textAlign: 'right', fontWeight: 'bold', fontSize: '12px'}}>Last Revised: 1 June 2024</h6>
                                                        {/* <button type="button" onClick={()=>redirectRegistration()} className="btn btn-success" style={{ float: "left" }}>Back</button> */}
                                                        {/* <button type="button" onClick={()=>showImg()} className="btn btn-success" style={{ float: "left" }}>Show Image</button> */}
                                                    </div>
                                                </div>
                                                <br></br><br></br><br></br><br></br><br></br>
                                            </div>
                                            <div className="col-lg-3">

                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </form>
                        </div>
                    </main>
                </div>
            </div>
        </body>
    );
}

export default Terms;