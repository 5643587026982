import React, { useState, useEffect } from "react";
import { API_URL } from "./config";
import AdminHeader from "./AdminHeader";
import { Link, useNavigate } from "react-router-dom";
import Header from "./Header";
import { MD5 } from 'crypto-js';
import { toast } from "react-toastify";
import Swal from "sweetalert2";

const Settings = () => {
    // const [apiKey, setApiKey] = useState("");
    // const apiKey = [...Array(15)].map(() => Math.random().toString(36)[2].join(''));

    const navigate = useNavigate();

    const currentDate = new Date();

    const [curDate, setCurDate] = useState(currentDate);

    const [client_profile_id, SetClientProfileId] = useState('');
    const [sender_name, setSenderName] = useState('');

    let [isAdmin, setIsAdmin] = useState(false);

    const id = localStorage.getItem('async_client_profile_id');

    const [user_email, setUserEmail] = useState(localStorage.getItem('async_username'));
    const [username, setUsername] = useState(localStorage.getItem('userName'));

    const [clients, setClients] = useState([]);

    const [ senderID, setSenderID] = useState([]);


    useEffect(() => {
        const role = localStorage.getItem('async_role');
        if (role === 'Admin') {
            setIsAdmin(true);
        }
        if (role === '' || role === null) {
            window.location.href = '/';
        }
    }, [])

    const sendApiEmail = (apikey) => {
        const userObj = { username, user_email, apikey };
        console.log(userObj);

        fetch(`${API_URL}/mailer/apikey/`, {
            method: "POST",
            headers: { "content-type": "application/json" },
            body: JSON.stringify(userObj)
        }).then(res => {
            // alert('saved successfully!');
        }).catch((err) => {
            console.log(err.message)
        })
    }

    const handleFormSubmit = (e) => {
        e.preventDefault();
        console.log(id);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const userObj = { client_profile_id, sender_name, curDate };
        console.log(userObj);

        fetch(`${API_URL}/senderid`, {
            method: "POST",
            headers: { "content-type": "application/json" },
            body: JSON.stringify(userObj)
        }).then(res => {
            Swal.fire({                                            
                text: "saved successfully",
                icon: "success"
              });
            navigate(0)
        }).catch((err) => {
            console.log(err.message)
        })
    }

    // const clients = [
    //     { id: 1, label: 'Test 2' },
    //     { id: 2, label: 'Option 2' },
    //     { id: 3, label: 'data 3' }
    //     // Add more data as needed
    // ];

    useEffect(() => {
        fetch(`${API_URL}/clients`).then(res => {
            return res.json()
        }).then(resp => {
            setClients(resp);
            // console.log("rrr", resp)
        }).catch((err) => {
            console.log(err.message);
        })
    }, [])

    useEffect(() => {
        fetch(`${API_URL}/senderid`).then(res => {
            return res.json()
        }).then(resp => {
            setSenderID(resp);
            // console.log("rrr", resp)
        }).catch((err) => {
            console.log(err.message);
        })
    }, [])


    const HandleRemove = (id) => {
        if (window.confirm('Do you want to remove this contact?')) {
            fetch(`${API_URL}/contacts/${id}`, {
                method: "DELETE"
            }).then(resp => {
                Swal.fire({                                            
                    text: "successfully deleted!",
                    icon: "success"
                  });
                // navigate(0);
            }).catch((err) => {
                console.log(err.message);
            })
        }
    }

    return (

        <div>
            {isAdmin ? <AdminHeader /> : null}
            {/* {!isAdmin ? <Header /> : null} */}
            <body class="sb-nav-fixed">
                <div id="layoutSidenav">
                    <div id="layoutSidenav_content">
                        <main>
                            <div class="container-fluid px-4">
                                <form className="container" onSubmit={handleSubmit}>
                                    <div className="row">
                                        <div className="offset-lg-3 col-lg-6"><br></br><br></br>
                                            <div className="card">
                                                <div className="card-header">
                                                    <h2>Register Sender Id</h2>
                                                </div>
                                                <div className="card-body">
                                                    <div className="form-group">
                                                        <label style={{ float: "left" }}>Client Profile Id</label> <br></br>
                                                        <input type="text" value={client_profile_id} onChange={e => SetClientProfileId(e.target.value)} className="form-control" /><br></br>
                                                    </div>
                                                    {/* <div className="form-group">
                                                        <label style={{ float: "left" }}>Client Profile Id</label> <br />
                                                        <select value={client_profile_id} onChange={e => SetClientProfileId(e.target.value)} className="form-control">
                                                            {clients.map(option => (
                                                                <option key={option.client_profile_id} value={option.client_profile_id} >{option.name}</option>
                                                            ))}
                                                        </select>
                                                    </div> */}
                                                    <div className="form-group">
                                                        <label style={{ float: "left" }}>Sender name</label> <br></br>
                                                        <input type="text" value={sender_name} onChange={e => setSenderName(e.target.value)} className="form-control" /><br></br>
                                                    </div>
                                                </div>
                                                
                                                <div className="card-footer">
                                                    <button type="submit" className="btn btn-success" style={{ float: "left" }}>Save</button>
                                                </div>
                                            </div>
                                            <div><br></br><br></br>
                                                    <table className="table table-bordered">
                                                        <thead className="bg-dark text-light">
                                                            <tr>
                                                                <td style={{ color: 'white' }}>ID</td>
                                                                <td style={{ color: 'white' }}>Company Id</td>
                                                                {/* <td style={{ color: 'white' }}>Company Name</td> */}
                                                                <td style={{ color: 'white' }}>Sender name</td>
                                                                <td style={{ color: 'white' }}>Date Registered</td>
                                                                <td style={{ color: 'white' }}>Action</td>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {
                                                                senderID.map((item) => (
                                                                    <tr key={item.sender_id}>
                                                                        <td>{item.sender_id}</td>
                                                                        <td>{item.client_profile_id}</td>
                                                                        <td>{item.sender_name}</td>
                                                                        <td>{item.date_created}</td>
                                                                        <td>
                                                                            {/* <a href="{EditUser/1" >Edit </a>_ */}
                                                                            <Link onClick={() => { HandleRemove(item.sender_id) }} style={{color: "blue", cursor:"pointer"}} >Remove</Link>
                                                                        </td>
                                                                    </tr>
                                                                ))
                                                            }
                                                        </tbody>
                                                    </table>
                                                </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </main>
                    </div>
                </div>
            </body>
        </div>
    );
};

export default Settings;