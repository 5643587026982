import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Header from "./Header";
import AdminHeader from "./AdminHeader";
import { API_URL } from "./config";

const EditGroup = () => {
    const [name, setName] = useState("");
    const [client_id] = useState(localStorage.getItem("async_client_profile_id"));
    const [isAdmin, setIsAdmin] = useState("");
    const [dest_phone, setdest_phone] = useState([]);
    const [contacts, setContacts] = useState([]);
    const { id } = useParams();

    const navigate = useNavigate();

    useEffect(() => {
        const role = localStorage.getItem("async_role");
        if (role === "Admin") {
            setIsAdmin(true);
        }
        if (role === "" || role === null) {
            window.location.href = "/";
        }

        fetch(`${API_URL}/groups/${id}`)
            .then((res) => res.json())
            .then((resp) => {
                const group = resp[0];
                setName(group.name);
                setdest_phone(group.dest_phone.split(", "));
            })
            .catch((err) => {
                console.log(err.message);
            });
    }, [id]);

    useEffect(() => {
        const client_profile_id = localStorage.getItem("async_client_profile_id");
        fetch(`${API_URL}/contacts/contacts/${client_profile_id}`)
            .then((res) => res.json())
            .then((resp) => {
                setContacts(resp);
            })
            .catch((err) => {
                console.log(err.message);
            });
    }, []);

    const handleSubmit = (e) => {
        e.preventDefault();
        const contactIDs = contacts.map((contact) => contact.contact_id);
        const userObj = { name, client_id, contactIDs };

        fetch(`${API_URL}/groups`, {
            method: "POST",
            headers: { "content-type": "application/json" },
            body: JSON.stringify(userObj),
        })
            .then((res) => {
                alert("Saved successfully!");
                navigate("/Groups");
            })
            .catch((err) => {
                console.log(err.message);
            });
    };

    const handleContactCheckboxChange = (contactId) => {
        const checkedContact = contacts.find((contact) => contactId === contact.contact_id);

        if (checkedContact) {
            const selectedId = checkedContact.phone;
            const existingIds = dest_phone;
            let updatedIds;

            if (existingIds.includes(selectedId)) {
                updatedIds = existingIds.filter((id) => id !== selectedId);
            } else {
                updatedIds = [...existingIds, selectedId];
            }

            setdest_phone(updatedIds);
        } else {
            const updatedIds = dest_phone.filter((id) => id !== contactId);
            setdest_phone(updatedIds);
        }
    };

    return (
        <>
            {isAdmin ? <AdminHeader /> : null}
            {/* {isAdmin ? <AdminHeader /> : <Header />} */}
            <div id="layoutSidenav">
                <div id="layoutSidenav_content">
                    <main>
                        <div className="container-fluid px-4">
                            <ol className="breadcrumb mb-4">
                                <li className="breadcrumb-item active"></li>
                            </ol>
                            <form className="container" onSubmit={handleSubmit}>
                                <div>
                                    <h1>Create Groups</h1>
                                    <br />
                                    <div className="row">
                                        <div className="col-lg-6">
                                            <div className="card">
                                                <div className="card-header">
                                                    <h2>Add Group</h2>
                                                </div>
                                                <div className="card-body">
                                                    <div className="form-group">
                                                        <label style={{ float: "left" }}>Name</label> <br />
                                                        <input
                                                            type="text"
                                                            value={name}
                                                            onChange={(e) => setName(e.target.value)}
                                                            className="form-control"
                                                        />
                                                        <br />
                                                    </div>
                                                    <input
                                                        type="text"
                                                        value={dest_phone.join(", ")}
                                                        onChange={(e) => setdest_phone(e.target.value)}
                                                        id="to"
                                                        placeholder="Add contacts"
                                                        disabled="true"
                                                        className="form-control"
                                                    />
                                                </div>
                                                <div className="card-footer">
                                                    <button type="submit" className="btn btn-success" style={{ float: "left" }}>
                                                        Save
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-4">
                                            <div className="contact-box">
                                                <h3>Select Contacts</h3>
                                                <div style={{ marginLeft: "5px", textAlign: "left" }}>
                                                    {contacts.map((contact) => (
                                                        <div key={contact.contact_id}>
                                                            <input
                                                                type="checkbox"
                                                                id={contact.contact_id}
                                                                name={contact.contact_name}
                                                                checked={dest_phone.includes(contact.phone)}
                                                                onChange={() => handleContactCheckboxChange(contact.contact_id)}
                                                            />
                                                            <label htmlFor={contact.contact_id}>
                                                                {contact.contact_name} {contact.contact_surname}
                                                            </label>
                                                            <br />
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </main>
                </div>
            </div>
        </>
    );
}

export default EditGroup;