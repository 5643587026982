import React from "react";

const Documentation = () => {


    const jsonRequest = `
    {
      "apikey": "",
      "senderid": "",
      "unicode": "",
      "schedule": "",
      "sending-time": "",
      "sms-list": [
        {
          "message": "",
          "mobiles": "",
          "client-sms-ids": ""
        },
        {
          "message": "",
          "mobiles": "",
          "client-sms-ids": ""
        }
      ]
    }
      `;

    const jsonResponse = `
    {
    "status": {
        "error-code": "",
        "error-status": "",
        "error-description": ""
    },
    "sms-response-details": [
        {
            "success-count": "",
            "failed-sms-details": [
                {
                    "count": "",
                    "reasons": [
                        {
                            "sms-client-id": "",                            
                            "mobile-no": "",
                            "failed-reason": "",
                            "message-content": ""
                        }
                    ]
                }
            ],
            "sent-sms-details": [
                {
                    "sms-client-id": "",
                    "message-id": "",
                    "mobile-no": ""
                }
            ]
        }
    ]
}
        `;

    return (
        <div>
            <body class="sb-nav-fixed">
                <div id="layoutSidenav">
                    <div id="layoutSidenav_content">
                        <main>
                            <div class="container-fluid px-4">
                                <div className="container">
                                    <h1 style={{ fontSize: '50px' }}><br></br>API Documentation</h1><br></br>
                                    {/* <div className="card">
                                        <div className="card-body">
                                            <h5 className="card-title">Getting Started</h5>
                                            <p className="card-text" style={{ textAlign: "center" }}>
                                                The documentation for the endpoint provides detailed information on how to utilize its functionalities effectively. It covers the following key aspects:
                                                <ul style={{ textAlign: "center", listStylePosition: "inside" }}>
                                                    <li>Overview of the endpoint's purpose and capabilities.</li>
                                                    <li>Request and response formats for interacting with the endpoint.</li>
                                                    <li>Required parameters and their expected values.</li>
                                                    <li>Optional parameters and their usage.</li>
                                                    <li>Examples demonstrating different use cases and scenarios.</li>
                                                    <li>Error handling and status codes for troubleshooting.</li>
                                                </ul>
                                                By referring to the documentation, developers can seamlessly integrate and leverage the endpoint within their applications, ensuring efficient and accurate communication with the desired functionality.
                                            </p>
                                            <a href="/docs/getting-started" className="btn btn-primary">
                                                View Documentation
                                            </a>
                                        </div>
                                    </div> */}
                                    <div >
                                        <div className="card-body" style={{ textAlign: 'left' }}>
                                            <h2 className="card-title">1. About Tell Them Message Service</h2>
                                            <p style={{paddingLeft: '30px', paddingRight:'15px', textAlign:'justify', fontSize: '12px'}}  ><br></br>
                                            Tell Them Message Service SMS Gateway by Softworks SMS Gateway is an efficient way to send instant SMS TXT messaging. Our SMS Gateway service allows you to send prompt messages, short messaging, and information pronto. We provide an easy, simple-to-use API integration facility for our end users, providing seamless end-to-end solutions. We allow you and your application to enable users to keep in touch with customers with dependable, fast SMS texting. Increase interaction right now! Boost your company's productivity with quick, efficient SMS messaging. Reach customers right away.
                                            <br></br><br></br>
                                            With strategic partnerships, affiliates, and alliances, Softworks offers a robust bulk TXT SMS gateway. We are a bulk SMS aggregator for all the 3 major mobile network operators in Zimbabwe with international coverage, sending messages to all destinations in the world.
                                            </p>
                                        </div>
                                    </div><br></br>

                                    <div>
                                        <div className="card-body" style={{ marginTop: '15px', textAlign: 'left' }}>
                                            <div>
                                                <h2 className="card-title">2. Sending SMS Sample Syntax</h2>
                                                <p style={{paddingLeft: '30px', paddingRight:'15px', textAlign:'justify', fontSize: '12px'}}  ><br></br> 
                                                    Use the following syntax to send an SMS message using the Softworks SMS Gateway API:
                                                </p>
                                                <pre style={{paddingLeft: '30px', paddingRight:'15px', textAlign:'justify', fontSize: '12px'}}  >
                                                    <code >
                                                        https://{`{Domain}`}/client/api/sendmessage?apikey={`{apikey}`}&mobiles={`{mobileno}`}&sms={`{message}`}&senderid={`{senderid}`}
                                                    </code>
                                                </pre>
                                            </div>
                                        </div>
                                    </div><br></br>

                                    <div>
                                        <div className="card-body" style={{ marginTop: '15px', textAlign: 'left' }}>
                                            <div>
                                                <h2 className="card-title">3. Http Parameter Definition</h2><br></br> 
                                                <table style={{ borderCollapse: 'collapse',  marginLeft:'30px', marginRight:'15px', paddingLeft: '30px', paddingRight:'15px', textAlign:'justify', fontSize: '12px'}}>
                                                    <thead>
                                                        <tr>
                                                            <th style={{ border: '1px solid black', padding: '8px' }}>Parameter</th>
                                                            <th style={{ border: '1px solid black', padding: '8px' }}>Value(Sample)</th>
                                                            <th style={{ border: '1px solid black', padding: '8px' }}>Required</th>
                                                            <th style={{ border: '1px solid black', padding: '8px' }}>Description</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td style={{ border: '1px solid black', padding: '8px' }}>apikey</td>
                                                            <td style={{ border: '1px solid black', padding: '8px' }}>12345abcde6789</td>
                                                            <td style={{ border: '1px solid black', padding: '8px' }}>Mandatory</td>
                                                            <td style={{ border: '1px solid black', padding: '8px' }}>Assigned apikey of user</td>
                                                        </tr>
                                                        <tr>
                                                            <td style={{ border: '1px solid black', padding: '8px' }}>mobiles</td>
                                                            <td style={{ border: '1px solid black', padding: '8px' }}>8745896587,8401530248,8866559944</td>
                                                            <td style={{ border: '1px solid black', padding: '8px' }}>Mandatory</td>
                                                            <td style={{ border: '1px solid black', padding: '8px' }}>Comma separated mobile numbers on which message will be delivered</td>
                                                        </tr>
                                                        <tr>
                                                            <td style={{ border: '1px solid black', padding: '8px' }}>sms</td>
                                                            <td style={{ border: '1px solid black', padding: '8px' }}>Test sms from api</td>
                                                            <td style={{ border: '1px solid black', padding: '8px' }}>Mandatory</td>
                                                            <td style={{ border: '1px solid black', padding: '8px' }}>Message content</td>
                                                        </tr>
                                                        <tr>
                                                            <td style={{ border: '1px solid black', padding: '8px' }}>senderid</td>
                                                            <td style={{ border: '1px solid black', padding: '8px' }}>teleoss</td>
                                                            <td style={{ border: '1px solid black', padding: '8px' }}>Optional</td>
                                                            <td style={{ border: '1px solid black', padding: '8px' }}>
                                                                Sender id of user. If you do not enter any specific sender id, then it will take default sender id, which is assigned to your account
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td style={{ border: '1px solid black', padding: '8px' }}>schedule</td>
                                                            <td style={{ border: '1px solid black', padding: '8px' }}>no/yes</td>
                                                            <td style={{ border: '1px solid black', padding: '8px' }}>Optional</td>
                                                            <td style={{ border: '1px solid black', padding: '8px' }}>If you want to schedule the message, set this to yes. By default, it's no.</td>
                                                        </tr>
                                                        <tr>
                                                            <td style={{ border: '1px solid black', padding: '8px' }}>sendingtime</td>
                                                            <td style={{ border: '1px solid black', padding: '8px' }}>Sending time.</td>
                                                            <td style={{ border: '1px solid black', padding: '8px' }}>Optional</td>
                                                            <td style={{ border: '1px solid black', padding: '8px' }}>
                                                                If the message needs to be sent to a particular recipient in the future, specify the date and time for scheduling the message. The format for date and time is configurable.
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td style={{ border: '1px solid black', padding: '8px' }}>unicode</td>
                                                            <td style={{ border: '1px solid black', padding: '8px' }}>yes/no (default set to no)</td>
                                                            <td style={{ border: '1px solid black', padding: '8px' }}>Optional</td>
                                                            <td style={{ border: '1px solidblack', padding: '8px' }}>
                                                                If you want to send Unicode SMS, enter 'yes'. If not provided, it will be considered non-Unicode by default.
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td style={{ border: '1px solid black', padding: '8px' }}>clientsms-ids</td>
                                                            <td style={{ border: '1px solid black', padding: '8px' }}>Third party id</td>
                                                            <td style={{ border: '1px solid black', padding: '8px' }}>Optional, but better to pass unique id</td>
                                                            <td style={{ border: '1px solid black', padding: '8px' }}>
                                                                If the client wants to receive the same ID in the response, they can set IDs in this parameter. There will be one ID for one message.
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td style={{ border: '1px solid black', padding: '8px' }}>messagetype</td>
                                                            <td style={{ border: '1px solid black', padding: '8px' }}>Message Type</td>
                                                            <td style={{ border: '1px solid black', padding: '8px' }}>Optional</td>
                                                            <td style={{ border: '1px solid black', padding: '8px' }}>
                                                                Message Type. The default is decided by the system. Possible values: sms, flash
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div><br></br>
                                    <h2 className="card-title" style={{ textAlign: 'left' }}><br></br>4. Sending JSON Request</h2><br></br>
                                    <div className="card" style={{ background: 'black', color: 'white', marginLeft:'30px', marginRight:'15px' }}>
                                        <div className="card-body" style={{ marginTop: '15px', textAlign: 'left' }}>
                                            <div>
                                                <div style={{ fontSize: '12px' }}>
                                                    <pre>{jsonRequest}</pre>
                                                </div>
                                            </div>
                                        </div>
                                    </div><br></br>
                                    <div>
                                        <div className="card-body" style={{ marginTop: '15px', textAlign: 'left', marginLeft:'30px', marginRight:'15px', fontSize: '12px'}}>
                                            <div>
                                                <h2 className="card-title" style={{marginLeft: '-25px'}}>5. Http Parameter Definition</h2><br></br>
                                                <table style={{ borderCollapse: 'collapse' }}>
                                                    <thead>
                                                        <tr>
                                                            <th style={{ border: '1px solid black', padding: '8px' }}>Parameter</th>
                                                            <th style={{ border: '1px solid black', padding: '8px' }}>Value</th>
                                                            <th style={{ border: '1px solid black', padding: '8px' }}>Description</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td style={{ border: '1px solid black', padding: '8px' }}>&lt;apikey&gt;</td>
                                                            <td style={{ border: '1px solid black', padding: '8px' }}>&lt;apikey&gt;cd9e4dcb8e9d68ed&lt;/apikey&gt;</td>
                                                            <td style={{ border: '1px solid black', padding: '8px' }}>Assigned apikey of user</td>
                                                        </tr>
                                                        <tr>
                                                            <td style={{ border: '1px solid black', padding: '8px' }}>&lt;senderid&gt;</td>
                                                            <td style={{ border: '1px solid black', padding: '8px' }}>&lt;senderid&gt;Aruhat&lt;/senderid&gt;</td>
                                                            <td style={{ border: '1px solid black', padding: '8px' }}>
                                                                Sender id of user. If you do not enter any specific sender id, then it will take default sender id, which is assigned to your account
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td style={{ border: '1px solid black', padding: '8px' }}>&lt;unicode&gt;</td>
                                                            <td style={{ border: '1px solid black', padding: '8px' }}>&lt;unicode&gt;yes&lt;/unicode&gt;</td>
                                                            <td style={{ border: '1px solid black', padding: '8px' }}>
                                                                If you want to send unicode SMS, then enter value as ‘yes’. If you do not provide value, then it will take nonunicode by default.
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td style={{ border: '1px solid black', padding: '8px' }}>&lt;schedule&gt;</td>
                                                            <td style={{ border: '1px solid black', padding: '8px' }}>&lt;schedule&gt;no&lt;/schedule&gt;</td>
                                                            <td style={{ border: '1px solid black', padding: '8px' }}>
                                                                If you want to schedule a message, set this to 'yes'. By default, it's 'no'.
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td style={{ border: '1px solid black', padding: '8px' }}>&lt;sending-time&gt;</td>
                                                            <td style={{ border: '1px solid black', padding: '8px' }}>&lt;sending-time&gt;14/07/2018 03:00:09&lt;/sending-time&gt;</td>
                                                            <td style={{ border: '1px solid black', padding: '8px' }}>
                                                                If a message needs to be sent to a particular recipient in the future, then a date and time can be scheduled for that message. The format for date and time is configurable.
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td style={{ border: '1px solid black', padding: '8px' }}>&lt;sms-list&gt;</td>
                                                            <td style={{ border: '1px solid black', padding: '8px' }}>List of sms object</td>
                                                            <td style={{ border: '1px solid black', padding: '8px' }}>
                                                                List of sms object if the client wants to send more than one message at the same time.
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td style={{ border: '1px solid black', padding: '8px' }}>&lt;sms&gt;</td>
                                                            <td style={{ border: '1px solid black', padding: '8px' }}>SMS Object</td>
                                                            <td style={{ border: '1px solid black', padding: '8px' }}>Actual object which contains SMS content, mobile number, and third party id.</td>
                                                        </tr>
                                                        <tr>
                                                            <td style={{ border: '1px solid black', padding: '8px' }}>&lt;message&gt;</td>
                                                            <td style={{ border: '1px solid black', padding: '8px' }}>&lt;Sorry, it seems the table got cut off in my previous response. Here's the continuation of the table:

                                                                ```jsx
                                                                message&gt;Actual Content&lt;/message&gt;</td>
                                                            <td style={{ border: '1px solid black', padding: '8px' }}>SMS content</td>
                                                        </tr>
                                                        <tr>
                                                            <td style={{ border: '1px solid black', padding: '8px' }}>&lt;mobiles&gt;</td>
                                                            <td style={{ border: '1px solid black', padding: '8px' }}>&lt;mobiles&gt;89********&lt;/mobiles&gt;</td>
                                                            <td style={{ border: '1px solid black', padding: '8px' }}>Destination address</td>
                                                        </tr>
                                                        <tr>
                                                            <td style={{ border: '1px solid black', padding: '8px' }}>&lt;client-sms-ids&gt;</td>
                                                            <td style={{ border: '1px solid black', padding: '8px' }}>&lt;client-sms-ids&gt;a7845AB&lt;/client-sms-ids&gt;</td>
                                                            <td style={{ border: '1px solid black', padding: '8px' }}>
                                                                If the client wants to give its own ID, they can set IDs in this parameter. It is considered as one ID for one message.
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td style={{ border: '1px solid black', padding: '8px' }}>&lt;message-type&gt;</td>
                                                            <td style={{ border: '1px solid black', padding: '8px' }}>&lt;message-type&gt;sms&lt;/messagetype&gt;</td>
                                                            <td style={{ border: '1px solid black', padding: '8px' }}>Message Type</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div><br></br>
                                    <h2 className="card-title" style={{ textAlign: 'left' }}>
                                        6. Sending JSON Response</h2><br></br>
                                       
                                    <div className="card"  style={{ background: 'black', color: 'white', marginLeft:'20px', textAlign:'justify', fontSize: '12px' }} >
                                        <div className="card-body" style={{ marginTop: '15px', textAlign: 'left' }}>
                                            <div>
                                                <div style={{ fontSize: '12px' }}>
                                                    <pre>{jsonResponse}</pre>
                                                </div>
                                            </div>
                                        </div>
                                    </div><br></br>
                                    <div>
                                        <div className="card-body" style={{ marginTop: '15px' }}>
                                            <div>
                                                <h2 className="card-title"  style={{ textAlign: 'left' }}>7. Error Codes</h2><br></br>
                                                <table style={{ borderCollapse: 'collapse', width: '97%', marginLeft:'30px', marginRight:'15px', fontSize: '12px' }}>
                                                    <thead>
                                                        <tr>
                                                            <th style={{ border: '1px solid black', padding: '8px' }}>Sr No</th>
                                                            <th style={{ border: '1px solid black', padding: '8px' }}>Error Code</th>
                                                            <th style={{ border: '1px solid black', padding: '8px' }}>Error Status</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td style={{ border: '1px solid black', padding: '8px' }}>1</td>
                                                            <td style={{ border: '1px solid black', padding: '8px' }}>101</td>
                                                            <td style={{ border: '1px solid black', padding: '8px' }}>Unknown</td>
                                                        </tr>
                                                        <tr>
                                                            <td style={{ border: '1px solid black', padding: '8px' }}>2</td>
                                                            <td style={{ border: '1px solid black', padding: '8px' }}>000</td>
                                                            <td style={{ border: '1px solid black', padding: '8px' }}>Success</td>
                                                        </tr>
                                                        <tr>
                                                            <td style={{ border: '1px solid black', padding: '8px' }}>3</td>
                                                            <td style={{ border: '1px solid black', padding: '8px' }}>001</td>
                                                            <td style={{ border: '1px solid black', padding: '8px' }}>required</td>
                                                        </tr>
                                                        <tr>
                                                            <td style={{ border: '1px solid black', padding: '8px' }}>4</td>
                                                            <td style={{ border: '1px solid black', padding: '8px' }}>002</td>
                                                            <td style={{ border: '1px solid black', padding: '8px' }}>invalid</td>
                                                        </tr>
                                                        <tr>
                                                            <td style={{ border: '1px solid black', padding: '8px' }}>5</td>
                                                            <td style={{ border: '1px solid black', padding: '8px' }}>003</td>
                                                            <td style={{ border: '1px solid black', padding: '8px' }}>failed</td>
                                                        </tr>
                                                        <tr>
                                                            <td style={{ border: '1px solid black', padding: '8px' }}>6</td>
                                                            <td style={{ border: '1px solid black', padding: '8px' }}>004</td>
                                                            <td style={{ border: '1px solid black', padding: '8px' }}>duplicate</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div><br></br>
                                    <div >
                                        <div className="card-body" style={{ marginTop: '15px', fontSize: '12px' }}>
                                            <div>
                                                <h2 className="card-title"  style={{ textAlign: 'left' }}>7. SMS Charges</h2><br></br>
                                                <p style={{textAlign:'left', marginLeft:'30px', marginRight:'15px'}}>
                                                    Our SMS solution offers 50 free TXT SMS for test purposes. However then after you will be required to pay $150 for initial Account Top Up. Refer to client Support for Top Up Procedure.
                                                </p><br></br>

                                                <table style={{ borderCollapse: 'collapse', marginLeft:'30px', marginRight:'15px' }}>
                                                    <thead>
                                                        <tr>
                                                            <th style={{ border: '1px solid black', padding: '8px' }}>Country/Destination</th>
                                                            <th style={{ border: '1px solid black', padding: '8px' }}>National Application To Phone Traffic Operator</th>
                                                            <th style={{ border: '1px solid black', padding: '8px' }}>Quota units/batch purchase/Order</th>
                                                            <th style={{ border: '1px solid black', padding: '8px' }}>Price/160-Character SMS message (USD) exclusive of taxes</th>
                                                            <th style={{ border: '1px solid black', padding: '8px' }}>Price/160-Character SMS message (USD) inclusive of taxes</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td style={{ border: '1px solid black', padding: '8px' }}>National
                                                                Application To Phone Traffic</td>
                                                            <td style={{ border: '1px solid black', padding: '8px' }}>All</td>
                                                            <td style={{ border: '1px solid black', padding: '8px' }}>Any Quantity</td>
                                                            <td style={{ border: '1px solid black', padding: '8px' }}>0.04 USD</td>
                                                            <td style={{ border: '1px solid black', padding: '8px' }}>0.046 USD</td>
                                                        </tr>
                                                    </tbody>
                                                </table><br></br>

                                                <p style={{ fontWeight: 'bold', textAlign:'left', marginLeft:'30px', marginRight:'15px'}}>
                                                    For international Routes/ Destinations Kindly specify the Destination and a corresponding tariffs/charges shall be shared and the charges attract accordingly.
                                                    <p style={{fontWeight: 'light'}}>Notes: For ZIG Payment local official exchange rates shall prevail.</p>
                                                </p>
                                            </div>
                                        </div>
                                    </div><br></br>
                                    <div >
                                        <div className="card-body" style={{ marginTop: '15px', fontSize: '12px' }}>
                                            <div>
                                                <h2 className="card-title"  style={{ textAlign: 'left' }}>8. Client Services & Support</h2><br></br>
                                                <p style={{textAlign:'left', marginLeft:'30px', marginRight:'15px'}}>
                                                    For any issue please do not hesitate to get in touch with our client services team.
                                                </p>

                                                <h6 style={{textAlign:'left', marginLeft:'30px', marginRight:'15px'}}>Our client service support is 24hrs</h6><br></br>
                                                <table style={{ borderCollapse: 'collapse', marginLeft:'30px', marginRight:'15px', width:'96%' }}>
                                                    <thead>
                                                        <tr>
                                                            <th style={{ border: '1px solid black', padding: '8px' }}></th>
                                                            <th style={{ border: '1px solid black', padding: '8px' }}>Contact</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td style={{ border: '1px solid black', padding: '8px' }}>Client Support</td>
                                                            <td style={{ border: '1px solid black', padding: '8px' }}>+263 773406449</td>
                                                        </tr>
                                                        <tr>
                                                            <td style={{ border: '1px solid black', padding: '8px' }}>Client Support</td>
                                                            <td style={{ border: '1px solid black', padding: '8px' }}>+263 777770330</td>
                                                        </tr>
                                                        <tr>
                                                            <td style={{ border: '1px solid black', padding: '8px' }}>Client Support</td>
                                                            <td style={{ border: '1px solid black', padding: '8px' }}>+263 773228829</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div><br></br>
                                </div>
                            </div>
                        </main>
                    </div>
                </div>
            </body>
        </div>
    );
};

export default Documentation;