import React, { useState, useEffect } from "react";
import { MD5 } from 'crypto-js';
import { API_URL } from "./config";
import { BarLoader } from "react-spinners";
import { toast } from "react-toastify";
import Swal from "sweetalert2";

const Login = () => {

    const [email, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [passwordHash, setPasswordHash] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        localStorage.clear();
    }, []);

    const checkOtp = (user_email, user_otp) => {
        console.log('function called');
        console.log('user email: ', user_email);
        console.log('user otp: ', user_otp);
        try {
            fetch(`${API_URL}/users/user/${user_email}/${user_otp}`)
                .then(res => res.json())
                .then(resp => {
                    console.log('function called 2');
                    console.log(resp.length);
                    if (resp.length > 0) {
                        console.log(resp[0].userid);
                        localStorage.setItem('user', resp[0].userid);
                        localStorage.setItem('async_client_profile_id', resp[0].client_profile_id);
                        localStorage.setItem('async_role', resp[0].role);
                        localStorage.setItem('async_username', resp[0].email);
                        const id = resp[0].userid;
                        window.location.href = '/passwordsetup';
                    } else {
                        Swal.fire({                                            
                            text: "Login Failed",
                            icon: "error"
                          });
                    }
                })
                .catch(err => {
                    console.log(err.message);
                    Swal.fire({                                            
                        text: "Login Failed",
                        icon: "error"
                      });
                    // alert("Login failed, check your network connection!");
                });
        } catch (error) {
            console.log(error);
            Swal.fire({                                            
                text: "Login Failed",
                icon: "error"
              });
        }
    };

    useEffect(() => {
        setPasswordHash(MD5(password).toString());
    }, [password])

    const handleSubmit = (e) => {
        e.preventDefault();
        console.log(passwordHash);
        try {
            setIsLoading(true);
            fetch(`${API_URL}/users/${email}/${passwordHash}`)
                .then(res => res.json())
                .then(resp => {

                    if (resp.length === 1) {
                        console.log(resp.length);
                        console.log(resp[0].userid);
                        localStorage.setItem('user', resp[0].userid);
                        localStorage.setItem('userName', resp[0].username);
                        localStorage.setItem('async_client_profile_id', resp[0].client_profile_id);
                        localStorage.setItem('async_role', resp[0].role);
                        localStorage.setItem('async_username', resp[0].email);

                        if (resp[0].role === 'Admin') {
                            window.location.href = '/AdminDashboard';
                        } else {
                            window.location.href = '/Dashboard';
                        }

                    } else {
                        try {
                            fetch(`${API_URL}/users/user/${email}/${password}`)
                                .then(res => res.json())
                                .then(resp => {
                                    console.log('function called 2');
                                    console.log(resp.length);
                                    if (resp.length > 0) {
                                        console.log(resp[0].userid);
                                        localStorage.setItem('user', resp[0].userid);
                                        localStorage.setItem('async_client_profile_id', resp[0].client_profile_id);
                                        localStorage.setItem('async_role', resp[0].role);
                                        localStorage.setItem('async_username', resp[0].email);
                                        const id = resp[0].userid;
                                        window.location.href = '/passwordsetup';
                                    } else {
                                        // toast.warning("Login failed, incorrect username or password");
                                    
                                        Swal.fire({
                                            
                                            text: "Incorrect Username or Password!",
                                            icon: "error"
                                          });

                                        setIsLoading(false);
                                    }
                                })
                                .catch(err => {
                                    console.log(err.message);
                                    setIsLoading(false);
                                    
                                    Swal.fire({                                            
                                        text: "Login Failed",
                                        icon: "error"
                                      });
                                    // alert("Login failed, check your network connection!");
                                });
                        } catch (error) {
                            console.log(error);
                            setIsLoading(false);
                            Swal.fire({                                            
                                text: "Login Failed",
                                icon: "error"
                              });
                        }
                    }
                })
                .catch(err => {
                    console.log(err.message);
                    // alert("Login failed, incorrect username or password");
                    setIsLoading(false);
                    Swal.fire({                                            
                        text: "Login failed, check your netork connection!",
                        icon: "error"
                      });
                });
        } catch (error) {
            console.log(error);
            setIsLoading(false);
            Swal.fire({                                            
                text: "Login Failed",
                icon: "error"
              });
        }
    };
    return (

        <div class="container-fluid" style={{ padding: 0, background: '#0d6efd', overflow: 'hidden' }}>

            <div class="row">

                <div class="col-lg-6 col-md-6 col-sm-12 col-xs-1">
                    <img src="/img/Bulk Text Smses Flyer.jpg" alt="Tell Them SMS GW" width="100%" height="95%" />
                </div>
                <div class="col-lg-1 col-md-1 col-sm-12 col-xs-12">

                </div>
                <div class="col-lg-4 col-md-4 col-sm-12 col-xs-12" style={{ marginTop: '60px' }}>

                    <div class="card shadow-lg border-1 rounded-lg mt-5">
                        <div class="card-header"><h5 class="text-center font-weight-light my-4">Login</h5></div>
                        <div class="card-body">
                            <form>
                                <div class="form-floating mb-3">
                                    <input type="text" value={email} onChange={e => setUsername(e.target.value)} id="email" class="form-control" required placeholder="name@example.com" />
                                    <label for="inputEmail">Email address</label>
                                </div>
                                <div class="form-floating mb-3">
                                    <input type="password" value={password} onChange={e => setPassword(e.target.value)} id="password" class="form-control" required placeholder="Password" />
                                    <label for="inputPassword">Password</label>
                                </div>
                                <div class="form-check mb-3" align="left">
                                    <input class="form-check-input" id="inputRememberPassword" type="checkbox" value="" />
                                    <label class="form-check-label" for="inputRememberPassword">Remember Password</label>
                                </div>
                                <div class=" row col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                    <div class="col-lg-8 col-md-8 col-sm-8 col-xs-8" align="left">
                                        <a href="Register">Register! </a> &nbsp;|&nbsp;<a class="small" href="password.html">Forgot Password?</a>
                                    </div>
                                    <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                                        <button onClick={handleSubmit} class="btn btn-primary">Login</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                        {isLoading && <BarLoader size={40} width={'100%'} color="blue" loading />}
                        <div class="card-footer text-center py-3">

                            <div class="small">
                                <div class=" row col-lg-12 col-md-12 col-sm-12 col-xs-12">

                                    <div class="col-lg-12 col-md-12 col-sm-6 col-xs-6" align="right">
                                        <a href="http://www.softworkscapital.com">www.softworkscapital.com</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-1 col-md-1">

                </div>
            </div>

            <script src="https://cdn.jsdelivr.net/npm/bootstrap@5.2.3/dist/js/bootstrap.bundle.min.js" crossorigin="anonymous"></script>
            <script src="js/scripts.js"></script>
        </div>

    );
}

export default Login;