import React, { useState } from "react";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import Header from "./Header";
import AdminHeader from "./AdminHeader";
import { API_URL } from "./config";

const AdminContacts = () => {

    const [contactList, setContactList] = useState([]);
    const [isAdmin, setIsAdmin] = useState('');

    useEffect(() => {
        const role = localStorage.getItem('async_role');
        if (role === 'Admin') {
            setIsAdmin(true);
        }
        if(role === '' || role === null){
            window.location.href = '/';
        }
        
        fetch(`${API_URL}/contacts/`).then(res => {
            return res.json()
        }).then(resp => {
            setContactList(resp);
        }).catch((err) => {
            console.log(err.message);
        })
    }, [])

    return (
        <div>
            {isAdmin ? <AdminHeader /> : null}
            {/* {!isAdmin ? <Header /> : null} */}
            <body class="sb-nav-fixed">
                <div id="layoutSidenav">
                    <div id="layoutSidenav_content">
                        <main>
                            <div class="container-fluid px-4">
                                <h1 class="mt-4">Contacts</h1>
                                <ol class="breadcrumb mb-4">
                                    <li class="breadcrumb-item active"></li>
                                </ol>
                                <div style={{}}>
                                    <Link className="btn btn-success btnAdd" style={{ float: "right" }} to="/AddContacts">Add Contact</Link> 
                                </div>
                                <div>
                                    <Link className="btn btn-success btnAdd" style={{ float: "right" }} to="/groups">View Groups</Link>
                                </div>
                                <table className="table table-bordered">
                                    <thead className="bg-dark text-light">
                                        <tr>
                                            <td style={{ color: 'white' }}>Name</td>
                                            <td style={{ color: 'white' }}>Phone</td>
                                            <td style={{ color: 'white' }}>Email</td>
                                            <td style={{ color: 'white' }}>Action</td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            contactList.map((item) => (
                                                <tr key={item.contact_id}>
                                                    <td>{item.contact_name}</td>
                                                    <td>{item.phone}</td>
                                                    <td>{item.email}</td>
                                                    <td>
                                                        <a href="{EditUser/1" >Edit </a>_
                                                        <a href="/item" >Remove</a>
                                                    </td>
                                                </tr>
                                            ))
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </main>
                    </div>
                </div>
            </body>
        </div>
    );
}

export default AdminContacts;