import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import AdminHeader from "./AdminHeader";
import { API_URL } from "./config";
import Swal from "sweetalert2";

const AddTopUp = () => {

    const [currency, setCurrency] = useState('USD');
    const [exchange_rate, setExchange_rate] = useState(1);
    const [date, setDate] = useState('');
    const [credit, setCredit] = useState();
    const [debit, setDebit] = useState(0);
    const [balance, setBalance] = useState(0);
    const [description, setDescription] = useState('Account topup');
    const [client_profile_id, setClient_profile_id] = useState();
    const [isAdmin, setIsAdmin] = useState('');
    const [companyName, setCompanyName] = useState("");
    const [isVisible, setIsVisible] = useState(false)

    const [calBal, setCalBal] = useState(0);

    const [checkID, setCheckID] = useState(false);

    useEffect(() => {
        const role = localStorage.getItem('async_role');
        if (role === 'Admin') {
            setIsAdmin(true);
        }
        if (role === '' || role === null) {
            window.location.href = '/';
        }
    }, [])

    const navigate = useNavigate();

    const setNewBal = async () => {
        await handleSubmit(calBal);
        console.log(balance);
    }

    const handleSubmit = (balance) => {
        // e.preventDefault();

        const userObj = { currency, exchange_rate, date, credit, debit, balance, description, client_profile_id };
        console.log(userObj);

        fetch(`${API_URL}/TopUp`, {
            method: "POST",
            headers: { "content-type": "application/json" },
            body: JSON.stringify(userObj)

        }).then(res => {
            console.log(res)
            if (res.status === 200) {
                Swal.fire({
                    text: "saved successfully!",
                    icon: "success"
                });
                navigate('/Topup')
            }

        }).catch((err) => {
            console.log(err.message)
        })
    }

    const validate = (e) => {
        e.preventDefault();
        if (checkID === false || debit === 0) {
            Swal.fire({
                text: "Fill in all details",
                icon: "error"
            });
        } else {
            setNewBal();
        }
    }

    const getBalance = (clientid) => {

        fetch(`${API_URL}/topUp/lasttopup/${clientid}`).then(res => {
            return res.json()
        }).then(resp => {
            try {
                const Bal = resp.results[0].balance;
                setBalance(Bal);
            } catch (e) {
                setBalance(0);
            }

            // const updatedBal = (Bal + debit);
            // console.log("Debit",debit)
            // console.log("Bal",resp.results[0].balance);
            // console.log("Updated Bal",updatedBal)
        }).catch((err) => {
            console.log(err.message);
        })
    }

    const getClientDetails = (id) => {

        fetch(`${API_URL}/clients/${id}`).then(res => {
            return res.json()
        }).then(resp => {
            try {
                setCompanyName(resp[0].name);
                console.log(resp[0].name);
                setCheckID(true);
            } catch (e) {
                setCompanyName("");
                setCheckID(false);
            }
        }).catch((err) => {
            console.log(err.message);
            setCheckID(false);
        })

    }


    const updateBalance = (bal, debit) => {
        let num1 = parseFloat(bal);
        let num2 = parseFloat(debit);
        let total = num1 + num2;
        let sum = parseFloat(total);

        setCalBal(sum)

        console.log(sum)
    }

    useEffect(() => {
        updateBalance(balance, debit);
    }, [debit])

    useEffect(() => {
        getBalance(client_profile_id);
        getClientDetails(client_profile_id)
    }, [client_profile_id])

    useEffect(() => {
        if (calBal > 0) {
            setIsVisible(true)
        } else {
            setIsVisible(false)
        }
    }, [calBal])


    return (
        <html>
            {isAdmin ? <AdminHeader /> : null}
            {/* {!isAdmin ? <Header /> : null} */}

            <body class="sb-nav-fixed">

                <div id="layoutSidenav">

                    <div id="layoutSidenav_content">
                        <main>
                            <div class="container-fluid px-4">
                                {/* <h1 class="mt-4">Create User</h1> */}
                                <ol class="breadcrumb mb-4">
                                    <li class="breadcrumb-item active"></li>
                                </ol>
                                <form className="container" onSubmit={validate}>
                                    <div className="row">
                                        <div className="offset-lg-3 col-lg-6">
                                            <div className="card">
                                                <div className="card-header">
                                                    <h2>Top Up Account</h2>
                                                </div>
                                                <div className="card-body row ">
                                                    <div className="form-group row col-lg-6 col-md-6 col-sm-6 col-xs-12 ">
                                                        <label>Client ID</label> <br></br>
                                                    </div>

                                                    <div className="form-group row col-lg-6 col-md-6 col-sm-6 col-xs-12" >
                                                        <input type="text" value={client_profile_id} onChange={e => setClient_profile_id(e.target.value)} className="form-control" /><br></br><br></br>
                                                    </div>
                                                    <div className="form-group row col-lg-12 col-md-12 col-sm-12 col-xm-12" style={{ fontSize: '24px', fontWeight: 'bold', textAlign: 'center', marginTop: '20px' }}>
                                                        <label style={{ float: "left", }}>{companyName}</label> <br></br>
                                                    </div>
                                                    {/* <div className="form-group row col-lg-6 col-md-6 col-sm-6 col-xm-12">
                                                        <label style={{ float: "left" }}>Balance</label> <br></br>
                                                        </div> */}
                                                    <div className="form-group row col-lg-12 col-md-12 col-sm-12 col-xm-12">
                                                        <label style={{ float: "center", marginTop: '20px', fontWeight: 'bold' }}>${balance} USD</label> <br></br>
                                                    </div>

                                                    <div className="form-group row col-lg-6 col-md-6 col-sm-6 col-xm-12">
                                                        <input type="hidden" value={balance} onChange={e => setBalance(e.target.value)} className="form-control" /><br></br>
                                                    </div>


                                                    <div className="form-group row col-lg-12 col-md-12 col-sm-12 col-xm-12">
                                                        <div className="form-group col-lg-6 col-md-6 col-sm-6 col-xm-12">
                                                            <label style={{ float: "left" }}>Currency</label> <br></br>
                                                        </div>
                                                        <div className="form-group col-lg-6 col-md-6 col-sm-6 col-xm-12">
                                                            <input type="text" value={currency} onChange={e => setCurrency(e.target.value)} className="form-control" disabled /><br></br>
                                                        </div>




                                                        <div className="form-group col-lg-6 col-md-6 col-sm-6 col-xm-12">
                                                            <label style={{ float: "left" }}>Exchange Rate</label>
                                                        </div>
                                                        <div className="form-group col-lg-6 col-md-6 col-sm-6 col-xm-12">
                                                            <input type="text" value={exchange_rate} onChange={e => setExchange_rate(e.target.value)} className="form-control" disabled /><br></br>
                                                        </div>

                                                        <div className="form-group col-lg-6 col-md-6 col-sm-6 col-xm-12">
                                                            <label style={{ float: "left" }}>Date</label>
                                                        </div>
                                                        <div className="form-group col-lg-6 col-md-6 col-sm-6 col-xm-12">
                                                            <input type="date" value={date} onChange={e => setDate(e.target.value)} className="form-control" /><br></br>
                                                        </div>
                                                        {/* <div className="form-group">
                                                        <label style={{ float: "left" }}>Credit</label> <br></br>
                                                        <input type="number" value={credit} onChange={e => setCredit(e.target.value)} className="form-control" /><br></br>
                                                    </div> */}
                                                        <div className="form-group col-lg-4 col-md-4 col-sm-4 col-xm-12">
                                                            <label style={{ float: "left" }}>Reference</label>
                                                        </div>
                                                        <div className="form-group col-lg-8 col-md-8 col-sm-8 col-xm-12">
                                                            <input type="text" value={description} onChange={e => setDescription(e.target.value)} className="form-control" /><br></br>
                                                        </div>
                                                        <div className="form-group col-lg-6 col-md-6 col-sm-6 col-xm-12">
                                                            <label style={{ float: "left" }}>Amount</label>
                                                        </div>
                                                        <div className="form-group col-lg-6 col-md-6 col-sm-6 col-xm-12">
                                                            <input type="number" value={debit} onChange={e => setDebit(e.target.value)} className="form-control" /><br></br>
                                                        </div>
                                                        {isVisible ? <div className="form-group col-lg-6 col-md-6 col-sm-6 col-xm-12">
                                                            <label style={{ float: "left" }}>New Balance</label>
                                                        </div> : null}
                                                        {isVisible ? <div className="form-group col-lg-6 col-md-6 col-sm-6 col-xm-12">
                                                            <label >{calBal}</label><br></br>
                                                        </div> : null}
                                                    </div>
                                                </div>
                                                <div className="card-footer">
                                                    <button type="submit" className="btn btn-success" style={{ float: "left" }}>Save</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </main>
                    </div>
                </div>
            </body>
        </html>
    );
}

export default AddTopUp;