import React, { useState, useEffect } from "react";
import { MD5 } from 'crypto-js';
import { API_URL } from "./config";
import { toast } from "react-toastify";
import Swal from "sweetalert2";

const PasswordReset = () => {

    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [passwordHash, setPasswordHash]=useState('');
    const [userid, setUserId] = useState('')

    useEffect(() => {
        setUserId(localStorage.getItem('user'));
    }, []);

    useEffect(()=>{
        setPasswordHash(MD5(password).toString());
    },[password])


    const handleSubmit = (e) => {
        e.preventDefault();
        if(password === confirmPassword){
            try {
                const userObj = { passwordHash }
    
                console.log(userObj);
                console.log(userid);
    
                fetch(`${API_URL}/users/updatepassword/${userid}`, {
                    method: "PUT",
                    headers: { "content-type": "application/json" },
                    body: JSON.stringify(userObj)
                }).then(res => {
                    Swal.fire({                                            
                        text: "Your password has been reset, you can now login!",
                        icon: "success"
                      });
                    
                    window.location.href = '/';
                }).catch((err) => {
                    console.log(err.message)
                })
    
            } catch (error) {
                console.log(error);
                Swal.fire({                                            
                    text: "password setup failed, try again!",
                    icon: "error"
                  });
            }
        }else{
            Swal.fire({                                            
                text: "Your passwords are not matching!",
                icon: "error"
              });
        }
    };
    return (

        <div class="container-fluid" style={{ padding: 0, background: '#0d6efd' }}>

            <div class="row">

                <div class="col-lg-5">
                    <img src="/img/Bulk Text Smses Flyer.jpg" alt="Tell Them SMS GW" width="100%" height="100%" />
                </div>
                <div class="col-lg-2">

                </div>
                <div class="col-lg-4" style={{ width: '30%' }}>

                    <div class="card shadow-lg border-1 rounded-lg mt-5">
                        <div class="card-header"><h5 class="text-center font-weight-light my-4">Create Password</h5></div>
                        <div class="card-body">
                            <form>
                                <div class="form-floating mb-3">
                                    <input type="password" value={password} onChange={e => setPassword(e.target.value)} id="email" class="form-control" required placeholder="name@example.com" />
                                    <label for="inputEmail">Enter Password</label>
                                </div>
                                <div class="form-floating mb-3">
                                    <input type="password" value={confirmPassword} onChange={e => setConfirmPassword(e.target.value)} id="email" class="form-control" required placeholder="name@example.com" />
                                    <label for="inputEmail">Confirm Password</label>
                                </div>
                                <div class="d-flex align-items-center justify-content-between mt-4 mb-0">
                                    <button onClick={handleSubmit} class="btn btn-primary">Enter</button>
                                </div>
                            </form>
                        </div>
                        <div class="card-footer text-center py-3">
                            <div class="small"><a href="Register">Register!</a></div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-1">

                </div>
            </div>
            <script src="https://cdn.jsdelivr.net/npm/bootstrap@5.2.3/dist/js/bootstrap.bundle.min.js" crossorigin="anonymous"></script>
            <script src="js/scripts.js"></script>
        </div>

    );
}

export default PasswordReset;