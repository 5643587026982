import React, { useState } from "react";
import { useEffect } from "react";
//import { useNavigation, useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import Header from "./Header";
import AdminHeader from "./AdminHeader";
import { API_URL } from "./config";

const Users = () => {

    const [isAdmin, setIsAdmin] = useState('');
    const [userList, setUserList] = useState([]);

    useEffect(() => {
        const role = localStorage.getItem('async_role');
        if (role === 'Admin') {
            setIsAdmin(true);
        }
        if(role === '' || role === null){
            window.location.href = '/';
        }
        fetch(`${API_URL}/users`).then(res => {
            return res.json()
        }).then(resp => {
            setUserList(resp);
        }).catch((err) => {
            console.log(err.message);
        })
    }, [])

    return (
        <div>
             {isAdmin ? <AdminHeader/> : null}
            {/* {isAdmin ? <AdminHeader /> : null}
            {!isAdmin ? <Header /> : null} */}
            <body class="sb-nav-fixed">

                <div id="layoutSidenav">

                    <div id="layoutSidenav_content">
                        <main>
                            <div class="container-fluid px-4">
                                <h1 class="mt-4">USERS</h1>
                                <ol class="breadcrumb mb-4">
                                    <li class="breadcrumb-item active"></li>
                                </ol>
                                <div style={{}}>
                                    <Link className="btn btn-success btnAdd" style={{ float: "right" }} to="/AddUser">Register User</Link>
                                    <Link className="btn btn-success btnAdd" style={{ float: "right" }} to="/Clients">View Clients </Link>
                                </div>
                                <table className="table table-bordered">
                                    <thead className="bg-dark text-light">
                                        <tr>
                                            <td style={{ color: 'white' }}>ID</td>
                                            <td style={{ color: 'white' }}>Company Id</td>
                                            {/* <td style={{ color: 'white' }}>Branch Id</td> */}
                                            <td style={{ color: 'white' }}>username</td>
                                            <td style={{ color: 'white' }}>Role</td>
                                            <td style={{ color: 'white' }}>Email</td>
                                            <td style={{ color: 'white' }}>Status</td>
                                            <td style={{ color: 'white' }}>Action</td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            userList.map((item) => (
                                                <tr key={item.userid}>
                                                    <td>{item.userid}</td>
                                                    <td>{item.client_profile_id}</td>
                                                    {/* <td>{item.branch_id}</td> */}
                                                    <td>{item.username}</td>
                                                    <td>{item.role}</td>
                                                    <td>{item.email}</td>
                                                    <td>{item.status}</td>
                                                    <td>
                                                        <a href="{EditUser/1" >Edit </a>_
                                                        <a href="/item" >Remove</a>
                                                    </td>
                                                </tr>
                                            ))
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </main>
                    </div>
                </div>
            </body>
        </div>
    );
}

export default Users;