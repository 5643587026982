import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Header from "./Header";
import AdminHeader from "./AdminHeader"
import { MD5 } from 'crypto-js'; 
import { API_URL } from "./config";
import { toast } from "react-toastify";
import Swal from "sweetalert2";

const AddUser = () => {

    const [company_id, setCompany_id] = useState('');
    const [branch_id, setBranch_id] = useState('');
    const [username, setUsername] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [role, setRole] = useState("");
    const [category, setCategory] = useState("");
    const [notify, setNotify] = useState("");
    const [activesession, setActivesession] = useState(""); //check
    const [addproperty, setAddproperty] = useState("");
    const [editproperty, setEditproperty] = useState("");
    const [approverequests, setApproverequests] = useState("");
    const [delivery, setDelivery] = useState("");
    const [status, setStatus] = useState();
    const [client_profile_id, setClient_profile_id] = useState(0);
    const [isAdmin, setIsAdmin] = useState('');

    useEffect(() => {
        const role = localStorage.getItem('async_role');
        if (role === 'Admin') {
            setIsAdmin(true);
        }
        if(role === '' || role === null){
            window.location.href = '/';
        }
    }, [])

    const navigate = useNavigate();

    const handleSubmit = (e) => {
        e.preventDefault();
        const passwordHash = MD5(password).toString();
        console.log(client_profile_id);
        const userObj = { company_id, branch_id, username, passwordHash, role, email, category, notify, activesession, addproperty, editproperty, approverequests, delivery, status, client_profile_id };
        console.log(userObj);

        fetch(`${API_URL}/users`, {
            method: "POST",
            headers: { "content-type": "application/json" },
            body: JSON.stringify(userObj)
        }).then(res => {
            Swal.fire({                                            
                text: "saved successfully!",
                icon: "success"
              });
            navigate('/Users')
        }).catch((err) => {
            console.log(err.message)
        })
    }

    return (
        <html>

            {isAdmin ? <AdminHeader /> : null}
            {/* {!isAdmin ? <Header /> : null} */}
            <body class="sb-nav-fixed">

                <div id="layoutSidenav">

                    <div id="layoutSidenav_content">
                        <main>
                            <div class="container-fluid px-4">
                                {/* <h1 class="mt-4">Create User</h1> */}
                                <ol class="breadcrumb mb-4">
                                    <li class="breadcrumb-item active"></li>
                                </ol>
                                <form className="container" onSubmit={handleSubmit}>
                                    <div className="row">
                                        <div className="offset-lg-3 col-lg-6">
                                            <div className="card">
                                                <div className="card-header">
                                                    <h2>Register User</h2>
                                                </div>
                                                <div className="card-body">
                                                    <div className="form-group">
                                                        <label style={{ float: "left" }}>Company Id</label> <br></br>
                                                        <input type="text" value={company_id} onChange={e => setCompany_id(e.target.value)} className="form-control" /><br></br>
                                                    </div>
                                                    <div className="form-group">
                                                        <label style={{ float: "left" }}>Branch Id</label> <br></br>
                                                        <input type="text" value={branch_id} onChange={e => setBranch_id(e.target.value)} className="form-control" /><br></br>
                                                    </div>
                                                    <div className="form-group">
                                                        <label style={{ float: "left" }}>Username</label> <br></br>
                                                        <input type="text" value={username} onChange={e => setUsername(e.target.value)} className="form-control" /><br></br>
                                                    </div>
                                                    <div className="form-group">
                                                        <label style={{ float: "left" }}>Password</label> <br></br>
                                                        <input type="password" value={password} onChange={e => setPassword(e.target.value)} className="form-control" /><br></br>
                                                    </div>
                                                    <div className="form-group">
                                                        <label style={{ float: "left" }}>Category</label> <br></br>
                                                        <input type="text" value={category} onChange={e => setCategory(e.target.value)} className="form-control" /><br></br>
                                                    </div>
                                                    <div className="form-group">
                                                        <label style={{ float: "left" }}>Email</label> <br></br>
                                                        <input type="text" value={email} onChange={e => setEmail(e.target.value)} className="form-control" /><br></br>
                                                    </div>
                                                    <div className="form-group">
                                                        <label style={{ float: "left" }}>Notify</label> <br></br>
                                                        <input type="text" value={notify} onChange={e => setNotify(e.target.value)} className="form-control" /><br></br>
                                                    </div>
                                                    <div className="form-group">
                                                        <label style={{ float: "left" }}>Role</label> <br></br>
                                                        <input type="text" value={role} onChange={e => setRole(e.target.value)} className="form-control" /><br></br>
                                                    </div>
                                                    <div className="form-group">
                                                        <label style={{ float: "left" }}>Add Property</label> <br></br>
                                                        <input type="text" value={addproperty} onChange={e => setAddproperty(e.target.value)} className="form-control" /><br></br>
                                                    </div>
                                                    <div className="form-group">
                                                        <label style={{ float: "left" }}>Edit Property</label> <br></br>
                                                        <input type="text" value={editproperty} onChange={e => setEditproperty(e.target.value)} className="form-control" /><br></br>
                                                    </div>
                                                    <div className="form-group">
                                                        <label style={{ float: "left" }}>Approve Requests</label> <br></br>
                                                        <input type="text" value={approverequests} onChange={e => setApproverequests(e.target.value)} className="form-control" /><br></br>
                                                    </div>
                                                    <div className="form-group">
                                                        <label style={{ float: "left" }}>Delivery</label> <br></br>
                                                        <input type="text" value={delivery} onChange={e => setDelivery(e.target.value)} className="form-control" /><br></br>
                                                    </div>
                                                    <div className="form-group">
                                                        <label style={{ float: "left" }}>Status</label> <br></br>
                                                        <input type="text" value={status} onChange={e => setStatus(e.target.value)} className="form-control" /><br></br>
                                                    </div>
                                                    <div className="form-group">
                                                        <label style={{ float: "left" }}>client_profile_id</label> <br></br>
                                                        <input type="text" value={client_profile_id} onChange={e => setClient_profile_id(e.target.value)} className="form-control" /><br></br>
                                                    </div>
                                                </div>
                                                <div className="card-footer">
                                                    <button type="submit" className="btn btn-success" style={{ float: "left" }}>Save</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </main>
                    </div>
                </div>
            </body>
        </html>
    );
}

export default AddUser;