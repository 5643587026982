import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";

const AdminHeader = () => {

    const [menuVisible, setMenuVisible] = useState(true);
    // const location = useLocation();
  
    // useEffect(() => {
    //   const role = localStorage.getItem('async_role');
    //   if (role === 'Admin') {
    //     setMenuVisible(true);
    //   } else {
    //     if (
    //       location.pathname === '/' ||
    //       location.pathname === '/Register' ||
    //       location.pathname === '/uploadappform' ||
    //       location.pathname === '/passwordsetup'
    //     ) {
    //       setMenuVisible(false);
    //     } else {
    //       setMenuVisible(true);
    //     }
    //   }
    // }, [location]);

    const [isOpen, setIsOpen] = useState(false);

    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    };

    return (
        <div> {menuVisible &&

            <html>
                <head>
                    <meta charset="utf-8" />
                    <meta http-equiv="X-UA-Compatible" content="IE=edge" />
                    <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
                    <meta name="description" content="" />
                    <meta name="author" content="" />
                    <title>Dashboard - SB Admin</title>
                    <link href="https://cdn.jsdelivr.net/npm/simple-datatables@7.1.2/dist/style.min.css" rel="stylesheet" />
                    <link href="css/styles.css" rel="stylesheet" />
                    <script src="https://use.fontawesome.com/releases/v6.3.0/js/all.js" crossorigin="anonymous"></script>
                </head>
                <body class="sb-nav-fixed">
                    <nav class="sb-topnav navbar navbar-expand navbar-dark" style={{ background: '#06357a' }} >
                        {/* <!-- Navbar Brand--> */}
                        <a class="navbar-brand ps-3" href="index.html">Tell Them Gateway</a>
                        {/* <!-- Sidebar Toggle--> */}
                        <button class="btn btn-link btn-sm order-1 order-lg-0 me-4 me-lg-0" id="sidebarToggle" href="#!"><i class="fas fa-bars"></i></button>
                        {/* <!-- Navbar Search--> */}
                        <form class="d-none d-md-inline-block form-inline ms-auto me-0 me-md-3 my-2 my-md-0">
                            <div class="input-group">
                                <input class="form-control" type="text" placeholder="Search for..." aria-label="Search for..." aria-describedby="btnNavbarSearch" />
                                <button class="btn btn-primary" id="btnNavbarSearch" type="button"><i class="fas fa-search"></i></button>
                            </div>
                        </form>
                        {/* <!-- Navbar--> */}
                        <ul class="navbar-nav ms-auto ms-md-0 me-3 me-lg-4">
                            <li class="nav-item dropdown">
                                <a class="nav-link dropdown-toggle" id="navbarDropdown" href="#0" role="button" data-bs-toggle="dropdown" aria-expanded="false"><i class="fas fa-user fa-fw"></i></a>
                                <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="navbarDropdown">
                                    <li><a class="dropdown-item" href="#!">Settings</a></li>
                                    <li><a class="dropdown-item" href="#!">Activity Log</a></li>
                                    <li><hr class="dropdown-divider" /></li>
                                    <li><a class="dropdown-item" href="#!">Logout</a></li>
                                </ul>
                            </li>
                        </ul>
                    </nav>
                    <div id="layoutSidenav">
                        <div id="layoutSidenav_nav">
                            <nav class="sb-sidenav accordion sb-sidenav-dark" id="sidenavAccordion">
                                <div class="sb-sidenav-menu">
                                    <div class="nav">
                                        <Link class="nav-link" to="/admindashboard">
                                            <div class="sb-nav-link-icon"><i class="fas fa-tachometer-alt"></i></div>
                                            Dashboard
                                        </Link>
                                        <Link class="nav-link" to="/selfregistration" >
                                            <div class="sb-nav-link-icon"><i class="fas fa-tachometer-alt"></i></div>
                                            Pending Registration
                                        </Link>
                                        <Link class="nav-link" to="/Topup">
                                            <div class="sb-nav-link-icon"><i class="fas fa-tachometer-alt"></i></div>
                                            Account Top Up
                                        </Link>
                                        <Link class="nav-link" to="/contacts">
                                            <div class="sb-nav-link-icon"><i class="fas fa-tachometer-alt"></i></div>
                                            Contacts
                                        </Link>
                                        <Link class="nav-link" to="/SendMessage">
                                            <div class="sb-nav-link-icon"><i class="fas fa-tachometer-alt"></i></div>
                                            Send New Messages
                                        </Link>
                                        <Link class="nav-link" to="/adminsentmessages">
                                            <div class="sb-nav-link-icon"><i class="fas fa-tachometer-alt"></i></div>
                                            My Sent Messages
                                        </Link>
                                        <Link class="nav-link" to="/Report">
                                            <div class="sb-nav-link-icon"><i class="fas fa-tachometer-alt"></i></div>
                                            Report
                                        </Link>
                                        <Link class="nav-link" to="/Users" >
                                            <div class="sb-nav-link-icon"><i class="fas fa-tachometer-alt"></i></div>
                                            Users
                                        </Link>
                                        <Link class="nav-link" to="/settings" >
                                            <div class="sb-nav-link-icon"><i class="fas fa-tachometer-alt"></i></div>
                                            Settings
                                        </Link>
                                        <div className={`dropdown ${isOpen ? "show" : ""}`}>
                                        <button
                                            className="btn btn-link nav-link dropdown-toggle"
                                            type="button"
                                            id="dropdownMenuButton"
                                            onClick={toggleDropdown}
                                        >
                                           API Integrations
                                        </button>
                                        <div className={`dropdown-menu ${isOpen ? "show" : ""}`} aria-labelledby="dropdownMenuButton">
                                            <a className="dropdown-item" href="/configapi">Config Bulk Post</a>
                                            <a className="dropdown-item" href="/documentation">Documentation</a>
                                            <a className="dropdown-item" href="/editconfig">Edit Config</a>
                                        </div>
                                    </div>
                                        <Link class="nav-link" to="/" >
                                            <div class="sb-nav-link-icon"><i class="fas fa-tachometer-alt"></i></div>
                                            Log Out
                                        </Link>
                                    </div>

                                </div>
                            </nav>
                        </div>

                    </div>
                </body>
            </html>
        }
        </div>
    );
}

export default AdminHeader;