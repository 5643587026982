import React, { useState } from "react";
import { API_URL } from './config';
import axios from "axios";
import sms6 from './sms6.png';
import bank from './bank.png';
import { BarLoader } from "react-spinners";
import { toast } from "react-toastify";
import Swal from "sweetalert2";

const UploadApplicationForm = () => {

    const [selectedFile, setSelectedFile] = useState(null);
    const [selectedImage, setSelectedImage] = useState(null);
    const [reg_id, setReg_id] = useState(null);
    const [email, setEmail] = useState('');

    const [isLoading, setIsLoading] = useState(false);

    //Alert
    const alertSuccess = () => {
        Swal.fire({
            title: "The Internet?",
            text: "That thing is still around?",
            icon: "question"
        });
    }

    const handleFileChange = (event) => {
        setSelectedFile(event.target.files[0]);
    };
    const handleImgChange = (event) => {
        setSelectedImage(event.target.files[0]);
    };

    const handleImageUpload = (event) => {
        event.preventDefault(); // Prevent default form submission
        setIsLoading(true);
        if (selectedImage) {
            console.log("Selected file true");
            const formData = new FormData();
            formData.append('pdfFile', selectedImage);

            axios.post(`${API_URL}/upload`, formData)
                .then((response) => {
                    console.log(response.data); // Response body containing the success message and filename

                    const lines = response.data.split('\n'); // Split the response by lines
                    if (lines.length > 0) {
                        const firstLine = lines[0]; // Get the first line
                        const imgname = firstLine.split('Filename: ')[1]; // Extract the filename from the first line
                        const imgpath = `${API_URL}/file/` + imgname;

                        if (imgname) {
                            console.log(imgname); // Log the filename

                        } else {
                            setIsLoading(false);
                            Swal.fire({
                                text: "Select all files to proceed!",
                                icon: "error"
                            });
                            console.log('Filename not found in the response');
                        }
                        handleFileUpload(imgpath);
                    } else {
                        console.log('Invalid response format');
                        setIsLoading(false);
                        Swal.fire({
                            text: "An error occured during file upload!",
                            icon: "error"
                        });
                    }
                })
                .catch((error) => {
                    console.error(error);
                    // Error occurred during file upload
                    console.log("Error response");
                    setIsLoading(false);
                    Swal.fire({
                        text: "An error occured during file upload!",
                        icon: "error"
                    });
                });

        } else {
            console.log('No file selected.');
            setIsLoading(false);
            Swal.fire({
                text: "Upload both files to continue!",
                icon: "error"
            });

        }
    };

    const handleFileUpload = (imgpath) => {
        if (selectedFile) {
            console.log("Selected file true");
            const formData = new FormData();
            formData.append('pdfFile', selectedFile);

            axios.post(`${API_URL}/upload`, formData)
                .then((response) => {
                    console.log(response.data); // Response body containing the success message and filename

                    const lines = response.data.split('\n'); // Split the response by lines
                    if (lines.length > 0) {
                        const firstLine = lines[0]; // Get the first line
                        const filename = firstLine.split('Filename: ')[1]; // Extract the filename from the first line
                        const filepath = `${API_URL}/file/` + filename;

                        if (filename) {
                            console.log(filename); // Log the filename
                        } else {
                            Swal.fire({
                                text: "Upload all files to proceed!",
                                icon: "error"
                            });
                            console.log('Filename not found in the response');
                        }

                        handleSubmit(imgpath, filepath);
                    } else {
                        console.log('Invalid response format');
                        setIsLoading(false);
                        Swal.fire({
                            text: "An error occured during file upload",
                            icon: "error"
                        });
                    }
                })
                .catch((error) => {
                    console.error(error);
                    // Error occurred during file upload
                    console.log("Error response");
                    setIsLoading(false);
                    Swal.fire({
                        text: "An error occured during file upload",
                        icon: "error"
                    });
                });

        } else {
            console.log('No file selected.');
            setIsLoading(false);
            Swal.fire({
                text: "Upload both files to continue",
                icon: "error"
            });
        }
    };

    const handleSubmit = (proof_of_payment, pdf_file) => {
        const userObj = { proof_of_payment, pdf_file };
        console.log(userObj);
        try {
            fetch(`${API_URL}/selftregistration/uploaddocs/${reg_id}/${email}`, {
                method: "PUT",
                headers: { "content-type": "application/json" },
                body: JSON.stringify(userObj)
            }).then(res => {
                // toast.success('Your details have been successfully submited, you will be notified via email once the Softworks Team review your application.')
                Swal.fire({
                    position: "center",
                    icon: "success",
                    title: "Your details have been successfully submited, you will be notified via email once the Softworks Team review your application.",
                    showConfirmButton: false,
                    timer: 1500
                });
                redirect();
            }).catch((err) => {
                console.log(err.message)
            })
        } catch (error) {
            Swal.fire({
                text: "Failed to submit details, check your interner connection",
                icon: "error"
            });
        }
    }

    const redirect = () => {
        window.location.href = '/';
    }

    const redirectReg = () => {
        window.location.href = '/Register';
    }

    return (
        <body className="sb-nav-fixed">

            <div id="layoutSidenav">

                <div id="layoutSidenav_content">
                    <main>
                        <div className="container-fluid px-4"
                            style={{
                                // height:'100%',
                                // width:'100%',
                                // opacity: '50%',
                                position: 'absolute',
                                resizeMode: 'stretch',
                                backgroundImage: `url('${sms6}')`,
                            }}
                        >
                            {/* <h1 className="mt-4">Create User</h1> */}
                            <ol className="breadcrumb mb-4">
                                <li className="breadcrumb-item active"></li>
                            </ol>
                            <form className="container" onSubmit={handleImageUpload}>
                                <div className="row">
                                    <div className="offset-lg-12">
                                        <div className="row">
                                            <div className="col-lg-2">

                                            </div>
                                            <div className="col-lg-8">
                                                <h6>Welcome to</h6>
                                                <h3 style={{ color: '#0842a0' }}>Tell Them Message Service </h3>
                                                <h6>Self Registration Portal </h6>
                                                <br></br>
                                                <div className="row mb-6" style={{ marginBottom: '-5px' }} >
                                                    <div className="col-lg-6" style={{ paddingRight: '0%' }}>
                                                        <button type="button" className="btn btn-info form-control" style={{ float: "left", backgroundColor: "#1FBED6", color: "#000000" }} onClick={redirectReg}>Step 1: Create Application Form     </button>
                                                    </div>
                                                    <div className="col-lg-6" style={{ paddingLeft: '0%' }}>
                                                        <button type="button" className="btn btn-info form-control" style={{ float: "left", backgroundColor: "#2299f2" }}>Step 2: Upload Application Form</button>

                                                    </div>
                                                </div>
                                                <div className="card"
                                                    style={{ borderWidth: 1, borderColor: '#0096ff80' }}
                                                >
                                                    <div className="card-header"
                                                        style={{ backgroundColor: '#2299f2' }}
                                                    >
                                                        <h5>Enter Your Details</h5>
                                                    </div>
                                                    <div className="card-body">
                                                        <div className="row">
                                                            <div className="col-lg-1">

                                                            </div>
                                                            <div className="col-lg-10">
                                                                <div>
                                                                    <div className="row form-group">
                                                                        <div className="alert alert-success " >
                                                                            <label style={{ textAlign: "justify", color: "#139E2F" }}>If you have completed stage 1. To complete the registration process, <br></br><br></br>(i) &nbsp;&nbsp;&nbsp; Fill in the application form sent to you via email at stage 1, <br></br> (ii)&nbsp;&nbsp;&nbsp; Use the application reference sent to you and upload form, <br></br>(iii) &nbsp;&nbsp;Kindly make a deposit using the below details for initial TopUp<br></br>(iv) &nbsp;&nbsp;Wait for application review Usually takes less than an Hour. </label> <br></br><br></br>
                                                                           
                                                                        </div>
                                                                        <div className="form-group row col-lg-12" >
                                                                            <div className=" col-lg-4 col-md-4" align="left" ><br></br><label>Reference No. : </label> <br></br></div>
                                                                            <div className=" col-lg-8 col-md-8" align="left"><br></br> <input style={{ borderWidth: 1, borderColor: '#00000070' }} type="number" value={reg_id} onChange={e => setReg_id(e.target.value)} className="form-control" required /><br></br></div>
                                                                        </div>
                                                                        <div className="form-group row col-lg-12" >
                                                                            <div className=" col-lg-4 col-md-4" align="left" ><label>Email: </label> <br></br></div>
                                                                            <div className=" col-lg-8 col-md-8" align="left" > <input style={{ borderWidth: 1, borderColor: '#00000070' }} type="email" value={email} onChange={e => setEmail(e.target.value)} className="form-control" required /><br></br></div>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div>
                                                                    <label style={{ float: "left", color: "#57aad0" }}>Upload Signed Application Form Document</label> <br></br>
                                                                    <input type="file" onChange={handleFileChange} style={{ float: "left" }} required />
                                                                    {/* <button onClick={handleUpload}>Upload</button> */}
                                                                </div>
                                                                <div><br></br><br></br>
                                                                    <label style={{ float: "left", color: "#57aad0" }}>Upload Proof of Payment Document</label> <br></br>
                                                                    <input type="file" onChange={handleImgChange} style={{ float: "left" }} required /><br></br><br></br><br></br>
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-1">

                                                            </div>
                                                            <div class="row" style={{ fontSize: '12px', backgroundColor: 'light-grey' }}><br></br>
                                                                <hr style={{ color: 'blue' }} />
                                                                <div className=" col-md-12 col-sm-12 col-sx-12 col-lg-12" align='center' ><h6>Banking Details</h6></div>

                                                                <div className=" col-md-4 col-sm-6 col-sx-6 col-lg-4" style={{ backgroundColor: '#fff' }}>
                                                                    <img src={bank} alt="" width="90%" height="100%" />

                                                                </div>
                                                                <div class="row col-md-8 col-sm-6 col-sx-6 col-lg-8">

                                                                    <div className=" col-md-6 col-sm-6 col-sx-6 col-lg-6" align='left' >

                                                                        <h7>Banker: </h7><br></br>
                                                                        <h7>Account Type: </h7><br></br>
                                                                        <h7>Account Number: </h7>
                                                                    </div>
                                                                    <div className=" col-md-6 col-sm-6 col-sx-6 col-lg-6" align='left' >

                                                                        <h7>CABS BANK</h7><br></br>
                                                                        <h7>Master Card</h7><br></br>
                                                                        <h7>1130562956 </h7>
                                                                    </div>
                                                                    <div className=" col-md-12 col-sm-12 col-sx-12 col-lg-12" align='left' style={{ color: 'blue' }}><br></br>
                                                                        Note: Cash payments are also allowed.
                                                                    </div>
                                                                </div>


                                                            </div>
                                                        </div>

                                                    </div>
                                                    {isLoading && <BarLoader size={40} width={'100%'} color="blue" loading />}
                                                    <div className="card-footer">
                                                        <button type="submit" className="btn btn-success" style={{ float: "left" }}>Save</button>
                                                        {/* <button type="button" onClick={()=>showImg()} className="btn btn-success" style={{ float: "left" }}>Show Image</button> */}
                                                    </div>
                                                </div>
                                                <br></br><br></br><br></br><br></br><br></br>
                                            </div>
                                            <div className="col-lg-2">

                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </form>
                        </div>
                    </main>
                </div>
            </div>
        </body>
    );
}

export default UploadApplicationForm;