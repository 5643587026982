import React, { useState } from "react";

const EditConfig = () => {
    const [isActive, setIsActive] = useState(true);
    const [apiKey, setApiKey] = useState("API_KEY");

    const handleDeactivate = () => {
        setIsActive(false);
    };

    const handleChangeKey = () => {
        const newApiKey = generateNewApiKey(); // Replace with your logic to generate a new API key
        setApiKey(newApiKey);
    };

    const handleDeleteConfig = () => {
        // Perform API configuration deletion logic here
        // This function can redirect to a confirmation page or display a modal dialog
        // with options to confirm or cancel the deletion
        console.log("Deleting API configuration...");
    };

    const generateNewApiKey = () => {
        // Replace this with your logic to generate a new API key
        return "NEW_API_KEY";
    };

    return (

        <div>
            <body class="sb-nav-fixed">
                <div id="layoutSidenav">
                    <div id="layoutSidenav_content">
                        <main>
                            <div class="container-fluid px-4">
                                <div className="container">
                                    <h2>Edit API Configuration</h2>
                                    <div className="card">
                                        <div className="card-body">
                                            <h5 className="card-title">API Key</h5>
                                            <p className="card-text">{apiKey}</p>
                                            <button className="btn btn-primary" onClick={handleChangeKey}>
                                                Change Key
                                            </button>
                                            <button className={`btn btn-${isActive ? "danger" : "success"}`} onClick={handleDeactivate}>
                                                {isActive ? "Deactivate" : "Activate"}
                                            </button>
                                            <button className="btn btn-danger" onClick={handleDeleteConfig}>
                                                Delete Configuration
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </main>
                    </div>
                </div>
            </body>
        </div>

    );
};

export default EditConfig;