import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Header from "./Header";
import AdminHeader from "./AdminHeader";
import { API_URL } from "./config";
import { toast } from "react-toastify";
import Swal from "sweetalert2";

const AddUser = () => {
    const [name, setName] = useState("");
    const [client_id] = useState(localStorage.getItem("async_client_profile_id"));
    const [isAdmin, setIsAdmin] = useState(false);
    const [currentPhone, setCurrentPhone] = useState("");
    const [dest_phone, setDestPhone] = useState("");
    const [contacts, setContacts] = useState([]);

    const navigate = useNavigate();

    useEffect(() => {
        const role = localStorage.getItem("async_role");
        if (role === "Admin") {
            setIsAdmin(true);
        }
        if (role === "" || role === null) {
            window.location.href = "/";
        }
    }, []);

    useEffect(() => {
        const client_profile_id = localStorage.getItem("async_client_profile_id");
        fetch(`${API_URL}/contacts/contacts/${client_profile_id}`)
            .then((res) => res.json())
            .then((resp) => {
                setContacts(resp);
                console.log(resp);
            })
            .catch((err) => {
                console.log(err.message);
            });
    }, []);

    const handleSubmit = (e) => {
        e.preventDefault();
        const contactIDs = contacts
            .filter((contact) => contact.checked)
            .map((contact) => contact.contact_id);
        console.log("Contact IDs: ", contactIDs);
        console.log("Group: ", name);
        const userObj = { name, client_id, contactIDs };
        console.log(userObj.contactIDs);

        fetch(`${API_URL}/groups`, {
            method: "POST",
            headers: { "content-type": "application/json" },
            body: JSON.stringify(userObj),
        })
            .then((res) => {
                Swal.fire({
                    text: "Saved successfully!",
                    icon: "success",
                });
                navigate("/Groups");
            })
            .catch((err) => {
                console.log(err.message);
            });
    };

    const handleContactCheckboxChange = (contactId) => {
        setContacts((prevContacts) =>
            prevContacts.map((contact) => {
                if (contact.contact_id === contactId) {
                    return {
                        ...contact,
                        checked: !contact.checked,
                    };
                }
                return contact;
            })
        );
    };

    return (
        <html>
            {isAdmin ? <AdminHeader /> : null}
            {/* {!isAdmin ? <Header /> : null} */}
            <body class="sb-nav-fixed">
                <div id="layoutSidenav">
                    <div id="layoutSidenav_content">
                        <main>
                            <div class="container-fluid px-4">
                                {/* <h1 class="mt-4">Create User</h1> */}
                                <ol class="breadcrumb mb-4">
                                    <li class="breadcrumb-item active"></li>
                                </ol>
                                <form className="container" onSubmit={handleSubmit}>
                                    <div>
                                        <h1>Create Groups</h1>
                                        <br></br>
                                        <div className="row">
                                            <div className="col-lg-6">
                                                <div className="card">
                                                    <div className="card-header">
                                                        <h2>Add Group</h2>
                                                    </div>
                                                    <div className="card-body">
                                                        <div className="form-group">
                                                            <label style={{ float: "left" }}>Name</label>{" "}
                                                            <br></br>
                                                            <input
                                                                type="text"
                                                                value={name}
                                                                onChange={(e) => setName(e.target.value)}
                                                                className="form-control"
                                                            />
                                                            <br></br>
                                                        </div>
                                                        {/* <input
                                                            type="text"
                                                            value={dest_phone}
                                                            onChange={(e) => setDestPhone(e.target.value)}
                                                            id="to"
                                                            placeholder="Add contacts"
                                                            disabled="true"
                                                            className="form-control"
                                                        /> */}
                                                    </div>
                                                    <div className="card-footer">
                                                        <button
                                                            type="submit"
                                                            className="btn btn-success"
                                                            style={{ float: "left" }}
                                                        >
                                                            Save
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-4">
                                                <div className="contact-box">
                                                    <h3>Select Contacts</h3>
                                                    <div style={{ marginLeft: "5px", textAlign: "left" }}>
                                                        {contacts.map((contact) => (
                                                            <div key={contact.contact_id}>
                                                                <input
                                                                    type="checkbox"
                                                                    id={contact.contact_id}
                                                                    name={contact.contact_name}
                                                                    checked={contact.checked}
                                                                    onChange={() =>
                                                                        handleContactCheckboxChange(contact.contact_id)
                                                                    }
                                                                />
                                                                <label htmlFor={contact.contact_id}>
                                                                    {contact.contact_name} {contact.contact_surname}
                                                                </label>
                                                                <br />
                                                            </div>
                                                        ))}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </main>
                    </div>
                </div>
            </body>
        </html>
    );
};

export default AddUser;