import './App.css';
import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Login from './Login';
import Dashboard from './Dashboard';
import Users from './Users';
import AddUser from './AddUser';
import EditUser from './EditUser';
import MyGroups from './MyGroups';
import AddGroup from './AddGroup';
import Contacts from './Contacts';
import AddContact from './AddContact';
import SendMessage from './SendMessage';
import SentMessages from './SentMessages';
//import AddTopUp from './AddTopUp';
import AddTopUp from './AddTopUp';
import TopUp from './topUp';
import AdminContacts from './admin_Contacts';
import AdminSentMessages from './admin_SentMessages';
import Reports from './Reports';
import Error from './Error';
import Clients from './Clients';
import AddClient from './AddClient'
import EditClient from './EditClient';
import SelfRegistration from './SelfRegistration';
import EditContact from './EditContact';
import PendingReg from './PendingRegistrations';
import Otp from './Otp';
import UploadApplicationForm from './UploadApplicationForm';
import AdminDashboard from './AdminDashboard';
import Terms from './Terms_Conditions';
import PasswordSetup from './PasswordSetup';
import EditGroup from './EditGroup';
import Header from './Header';
import { ToastContainer } from 'react-toastify';
import PasswordReset from './PasswordReset';
import ConfigureBulkApi from './ApiConfig';
import Documentation from './ApiDocumentation';
import EditConfig from './ApiEditConfig';
import Settings from './Settings';

function App() {

    return (
        <BrowserRouter>
            <div className='App'>
                <Header></Header>
                <Routes>
                    <Route path='/' element={<Login />}></Route>
                    <Route path='*' element={<Error />}></Route>
                    <Route path='/Dashboard' element={<Dashboard />}></Route>
                    <Route path='/AdminDashboard' element={<AdminDashboard />}></Route>
                    <Route path='/Users' element={<Users />}></Route>
                    <Route path='/AddUser' element={<AddUser />}></Route>
                    <Route path='/EditUser/:code' element={<EditUser />}></Route>
                    <Route path='/Groups' element={<MyGroups />}></Route>
                    <Route path='/AddGroup' element={<AddGroup />}></Route>
                    <Route path='/Contacts' element={<Contacts />}></Route>
                    <Route path='/admincontacts' element={<AdminContacts />}></Route>
                    <Route path='/AddContacts' element={<AddContact />}></Route>
                    <Route path='/SendMessage' element={<SendMessage />}></Route>
                    <Route path='/SentMessages' element={<SentMessages />}></Route>
                    <Route path='/adminsentmessages' element={<AdminSentMessages />}></Route>
                    <Route path='/TopUp' element={<TopUp />}></Route>
                    <Route path='/AddTopUp' element={<AddTopUp />}></Route>
                    <Route path='/Report' element={<Reports />}></Route>
                    <Route path='/Clients' element={<Clients />}></Route>
                    <Route path='/AddClient' element={<AddClient />}></Route>
                    <Route path='/EditClient/:id' element={<EditClient />}></Route>
                    <Route path='/EditContact/:id' element={<EditContact />}></Route>
                    <Route path='/Register' element={<SelfRegistration />}></Route>
                    <Route path='/selfregistration' element={<PendingReg />}></Route>
                    <Route path='/otp' element={<Otp />}></Route>
                    <Route path='/uploadappform' element={<UploadApplicationForm />}></Route>
                    <Route path='/terms' element={<Terms />}></Route>
                    <Route path='/passwordsetup' element={<PasswordSetup />}></Route>
                    <Route path='/passwordreset' element={<PasswordReset />}></Route>
                    <Route path='/editgroup/:id' element={<EditGroup />}></Route>
                    <Route path='/configapi' element={<ConfigureBulkApi />}></Route>
                    <Route path='/documentation' element={<Documentation />}></Route>
                    <Route path='/editconfig' element={<EditConfig />}></Route>
                    <Route path='/settings' element={<Settings/>}></Route>
                </Routes>
                <ToastContainer></ToastContainer>
            </div>
        </BrowserRouter>
    );
}

export default App;