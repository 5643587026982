import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Header from "./Header";
import AdminHeader from "./AdminHeader";
import { API_URL } from "./config";
import { toast } from "react-toastify";
import Swal from "sweetalert2";

const AddContact = () => {

    const [contact_name, setName] = useState('');
    const [surname, setSurname] = useState('');
    const [phone, setPhone] = useState('');
    const [email, setEmail] = useState('');
    const [client_id] = useState(localStorage.getItem('async_client_profile_id'));
    let [isAdmin, setIsAdmin] = useState(false);

    useEffect(() => {
        const role = localStorage.getItem('async_role');
        if (role === 'Admin') {
            setIsAdmin(true);
        }
        if(role === '' || role === null){
            window.location.href = '/';
        }
    }, [])

    const navigate = useNavigate();

    const handleSubmit = (e) => {
        e.preventDefault();
        const userObj = { phone, email, client_id, contact_name, surname };
        console.log(userObj);

        fetch(`${API_URL}/contacts`, {
            method: "POST",
            headers: { "content-type": "application/json" },
            body: JSON.stringify(userObj)
        }).then(res => {
            Swal.fire({                                            
                text: "saved successfully!",
                icon: "success"
              });
            navigate('/Contacts');
        }).catch((err) => {
            console.log(err.message)
        })
    }

    return (
        <html>
            {isAdmin ? <AdminHeader/> : null}
            {/* {!isAdmin ? <Header /> : null} */}
            <body class="sb-nav-fixed">

                <div id="layoutSidenav">

                    <div id="layoutSidenav_content">
                        <main>
                            <div class="container-fluid px-4">
                                {/* <h1 class="mt-4">Create User</h1> */}
                                <ol class="breadcrumb mb-4">
                                    <li class="breadcrumb-item active"></li>
                                </ol>
                                <form className="container" onSubmit={handleSubmit}>
                                    <div className="row">
                                        <div className="offset-lg-3 col-lg-6">
                                            <div className="card">
                                                <div className="card-header">
                                                    <h2>Add Contact</h2>
                                                </div>
                                                <div className="card-body">
                                                    <div className="form-group">
                                                        <label style={{ float: "left" }}>Name</label> <br></br>
                                                        <input type="text" value={contact_name} onChange={e => setName(e.target.value)} className="form-control" /><br></br>
                                                    </div>
                                                    <div className="form-group">
                                                        <label style={{ float: "left" }}>Surname</label> <br></br>
                                                        <input type="text" value={surname} onChange={e => setSurname(e.target.value)} className="form-control" /><br></br>
                                                    </div>
                                                    <div className="form-group">
                                                        <label style={{ float: "left" }}>Phone eg. +263777000000</label> <br></br>
                                                        <input type="text" value={phone} onChange={e => setPhone(e.target.value)} className="form-control" /><br></br>
                                                    </div>
                                                    <div className="form-group">
                                                        <label style={{ float: "left" }}>Email</label> <br></br>
                                                        <input type="text" value={email} onChange={e => setEmail(e.target.value)} className="form-control" /><br></br>
                                                    </div>
                                                </div>
                                                <div className="card-footer">
                                                    <button type="submit" className="btn btn-success" style={{ float: "left" }}>Save</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </main>
                    </div>
                </div>
            </body>
        </html>
    );
}

export default AddContact;