import React, { useState, useEffect } from "react";
import Header from "./Header";
import AdminHeader from "./AdminHeader";
import { API_URL } from "./config";

const SentMessages = () => {

    const[messageList, setMessageList] = useState([]);
    let [isAdmin,setIsAdmin] = useState(false);

    useEffect(()=>{
        const role = localStorage.getItem('async_role');
        if(role === 'Admin'){
            setIsAdmin(true);
        }
        if(role === '' || role === null){
            window.location.href = '/';
        }
        const client_profile_id = localStorage.getItem('async_client_profile_id');
        fetch(`${API_URL}/sentmessages/messages/${client_profile_id}`).then(res=>{
            return res.json()
        }).then(resp=>{
            setMessageList(resp);
        }).catch((err)=>{
            console.log(err.message);
        })
    },[])

    return (
        <div>
             {isAdmin ? <AdminHeader/> : null}
           {/* {isAdmin ? <AdminHeader /> : null}
           {!isAdmin ? <Header /> : null} */}
            <body class="sb-nav-fixed">
               
                <div id="layoutSidenav">

                    <div id="layoutSidenav_content">
                        <main>
                            <div class="container-fluid px-4">
                                <h1 class="mt-4">Sent Messages</h1>
                                <ol class="breadcrumb mb-4">
                                    <li class="breadcrumb-item active"></li>
                                </ol>
                                <div style={{}}>
                                    {/* <Link className="btn btn-success btnAdd" style={{float: "right"}} to="/AddGroup">Add Group</Link> */}
                                </div>
                                <table className="table table-bordered">
                                    <thead className="bg-dark text-light">
                                        <tr>
                                            <td style={{color: 'white'}}>Message Sent ID</td>
                                            {/* <td style={{color: 'white'}}>Client Profile</td> */}
                                            <td style={{color: 'white'}}>Message Type</td>
                                            {/* <td style={{color: 'white'}}>Origin Phone</td> */}
                                            <td style={{color: 'white'}}>Receipient</td>
                                            <td style={{color: 'white'}}>Date sent</td>
                                            {/* <td style={{color: 'white'}}>Group Name</td> */}
                                            {/* <td style={{color: 'white'}}>Contact Grouping Id</td> */}
                                            <td style={{color: 'white'}}>Message Body</td>
                                            <td style={{color: 'white'}}>VAT</td>
                                            <td style={{color: 'white'}}>Cost Incl</td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            messageList.map((item)=>(
                                                <tr key={item.messages_sent_id}>
                                                    <td>{item.messages_sent_id}</td>
                                                    {/* <td>{item.client_profile_id}</td> */}
                                                    <td>{item.message_type}</td>
                                                    {/* <td>{item.origin_phone}</td> */}
                                                    <td>{item.dest_phone}</td>
                                                    <td>{item.date_sent}</td>
                                                    {/* <td>{item.grp_name}</td> */}
                                                    {/* <td>{item.contact_grouping_id}</td> */}
                                                    <td>{item.msgbody}</td>
                                                    <td>{item.vat * 100} %</td>
                                                    <td>{item.cost_incl}</td>
                                                </tr>
                                            ))
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </main>
                    </div>
                </div>
            </body>
        </div>
    );
}

export default SentMessages;