import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Header from "./Header";
import AdminHeader from "./AdminHeader";
import { API_URL } from './config';
import { toast } from "react-toastify";
import Swal from "sweetalert2";

const EditClient = () => {

    const [client_profile_id, setClient_profile_id] = useState(0);
    const [account_type, setAccount_type] = useState('');
    const [account_category, setAccount_category] = useState('');
    const [signed_on, setSigned_on] = useState('');
    const [name, setName] = useState('');
    const [street, setStreet] = useState('');
    const [surbub, setSurbub] = useState('');
    const [city, setCity] = useState("");
    const [country, setCountry] = useState("");
    const [phoneno1, setPhoneno1] = useState("");
    const [phoneno2, setPhoneno2] = useState(""); //check
    const [email, setEmail] = useState("");
    const [status, setStatus] = useState("");
    const [payment_style, setPayment_style] = useState("");
    const [isAdmin, setIsAdmin] = useState('');
    const { id } = useParams();

    useEffect(() => {
        const role = localStorage.getItem('async_role');
        if (role === 'Admin') {
            setIsAdmin(true);
        }
        if(role === '' || role === null){
            window.location.href = '/';
        }

        fetch(`${API_URL}/clients/${id}`).then(res => {
            return res.json()
        }).then(resp => {
            console.log(resp[0])
            setClient_profile_id(resp[0].client_profile_id);
            setAccount_type(resp[0].account_type);
            setAccount_category(resp[0].account_category);
            setSigned_on(resp[0].signed_on);
            setName(resp[0].name);
            setStreet(resp[0].house_number_and_street_name);
            setSurbub(resp[0].surbub);
            setCity(resp[0].city);
            setCountry(resp[0].country);
            setPhoneno1(resp[0].phoneno1);
            setPhoneno2(resp[0].phoneno2);
            setEmail(resp[0].email);
            setStatus(resp[0].membershipstatus);
            setPayment_style(resp[0].payment_style);
            setIsAdmin(resp[0].isAdmin);
        }).catch((err) => {
            console.log(err.message);
        })

    }, [])

    const navigate = useNavigate();

    const handleSubmit = (e) => {
        e.preventDefault();
        const userObj = { id, account_type, account_category, signed_on, name, street, surbub, city, country, phoneno1, phoneno2, email, payment_style, status };
        console.log(userObj);

        fetch(`${API_URL}/clients/${id}`, {
            method: "PUT",
            headers: { "content-type": "application/json" },
            body: JSON.stringify(userObj)
        }).then(res => {
            Swal.fire({                                            
                text: "updated successfully!",
                icon: "success"
              });
            navigate('/Clients')
        }).catch((err) => {
            console.log(err.message)
        })
    }

    return (
        <html>
            {isAdmin ? <AdminHeader /> : null}
            {/* {!isAdmin ? <Header /> : null} */}
            <body class="sb-nav-fixed">

                <div id="layoutSidenav">

                    <div id="layoutSidenav_content">
                        <main>
                            <div class="container-fluid px-4">
                                {/* <h1 class="mt-4">Create User</h1> */}
                                <ol class="breadcrumb mb-4">
                                    <li class="breadcrumb-item active"></li>
                                </ol>
                                <form className="container" onSubmit={handleSubmit}>
                                    <div className="row">
                                        <div className="offset-lg-3 col-lg-6">
                                            <div className="card">
                                                <div className="card-header">
                                                    <h2>Edit Client Information</h2>
                                                </div>
                                                <div className="card-body">
                                                    <div className="form-group">
                                                        <label style={{ float: "left" }}>Client ID</label> <br></br>
                                                        <input disabled="true" type="text" value={client_profile_id} onChange={e => setClient_profile_id(e.target.value)} className="form-control" /><br></br>
                                                    </div>
                                                    <div className="form-group">
                                                        <label style={{ float: "left" }}>account_type</label> <br></br>
                                                        <input type="text" value={account_type} onChange={e => setAccount_type(e.target.value)} className="form-control" /><br></br>
                                                    </div>
                                                    <div className="form-group">
                                                        <label style={{ float: "left" }}>account_category</label> <br></br>
                                                        <input type="text" value={account_category} onChange={e => setAccount_category(e.target.value)} className="form-control" /><br></br>
                                                    </div>
                                                    <div className="form-group">
                                                        <label style={{ float: "left" }}>Date</label> <br></br>
                                                        <input type="date" value={signed_on} onChange={e => setSigned_on(e.target.value)} className="form-control" /><br></br>
                                                    </div>
                                                    <div className="form-group">
                                                        <label style={{ float: "left" }}>name</label> <br></br>
                                                        <input type="text" value={name} onChange={e => setName(e.target.value)} className="form-control" /><br></br>
                                                    </div>
                                                    <div className="form-group">
                                                        <label style={{ float: "left" }}>Street</label> <br></br>
                                                        <input type="text" value={street} onChange={e => setStreet(e.target.value)} className="form-control" /><br></br>
                                                    </div>
                                                    <div className="form-group">
                                                        <label style={{ float: "left" }}>Surbub</label> <br></br>
                                                        <input type="text" value={surbub} onChange={e => setSurbub(e.target.value)} className="form-control" /><br></br>
                                                    </div>
                                                    <div className="form-group">
                                                        <label style={{ float: "left" }}>City</label> <br></br>
                                                        <input type="text" value={city} onChange={e => setCity(e.target.value)} className="form-control" /><br></br>
                                                    </div>
                                                    <div className="form-group">
                                                        <label style={{ float: "left" }}>Country</label> <br></br>
                                                        <input type="text" value={country} onChange={e => setCountry(e.target.value)} className="form-control" /><br></br>
                                                    </div>
                                                    <div className="form-group">
                                                        <label style={{ float: "left" }}>Phone 1</label> <br></br>
                                                        <input type="text" value={phoneno1} onChange={e => setPhoneno1(e.target.value)} className="form-control" /><br></br>
                                                    </div>
                                                    <div className="form-group">
                                                        <label style={{ float: "left" }}>Phone 2</label> <br></br>
                                                        <input type="text" value={phoneno2} onChange={e => setPhoneno2(e.target.value)} className="form-control" /><br></br>
                                                    </div>
                                                    <div className="form-group">
                                                        <label style={{ float: "left" }}>Email</label> <br></br>
                                                        <input type="text" value={email} onChange={e => setEmail(e.target.value)} className="form-control" /><br></br>
                                                    </div>
                                                    <div className="form-group">
                                                        <label style={{ float: "left" }}>Membership status</label> <br></br>
                                                        <input type="text" value={status} onChange={e => setStatus(e.target.value)} className="form-control" /><br></br>
                                                    </div>
                                                    <div className="form-group">
                                                        <label style={{ float: "left" }}>Payment Style</label> <br></br>
                                                        <input type="text" value={payment_style} onChange={e => setPayment_style(e.target.value)} className="form-control" /><br></br>
                                                    </div>
                                                </div>
                                                <div className="card-footer">
                                                    <button type="submit" className="btn btn-success" style={{ float: "left" }}>Save</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </main>
                    </div>
                </div>
            </body>
        </html>
    );
}

export default EditClient;